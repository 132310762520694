<template>
  <v-container>
    <v-row v-if="agendamento">
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-uppercase">
            Emissão de Ticket/PROTOCOLO
          </v-card-title>

          <v-card flat>
            <v-card-title
              class="text-subtitle-1 text-uppercase font-weight-medium"
            >
              Dados do Cliente
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <InputView label="Nome" :value="agendamento.cliente.nome" />
                </v-col>
                <v-col cols="3">
                  <InputView
                    label="CPF/CNPJ"
                    :value="agendamento.cliente.cpfcnpj"
                  />
                </v-col>
                <v-col cols="6">
                  <InputView label="Email" :value="agendamento.cliente.email" />
                </v-col>
                <v-col cols="3">
                  <InputView
                    label="Atividade"
                    :value="agendamento.cliente.atividade"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-divider></v-divider>
          <v-card flat>
            <v-card-title
              class="text-subtitle-1 text-uppercase font-weight-medium"
            >
              Informação da Emissão
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <InputView
                    label="Ticket/protocolo"
                    :value="agendamento.atendimento.ticket"
                  />
                </v-col>
                <v-col cols="6">
                  <InputView
                    label="Produto"
                    :value="
                      !agendamento.cliente.produtoEmissao
                        ? agendamento.cliente.produtoSelecionado.nome
                        : agendamento.cliente.produtoEmissao
                    "
                  />
                </v-col>
                <v-col cols="4">
                  <InputView
                    label="Finalidade"
                    :value="agendamento.atendimento.finalidade"
                  />
                </v-col>
                <v-col cols="4">
                  <InputView
                    label="Agente Emissor"
                    :value="agendamento.atendimento.agente"
                  />
                </v-col>
                <v-col cols="4">
                  <InputView
                    label="Fornecedor"
                    :value="agendamento.cliente.fornecedor"
                  />
                </v-col>
                <v-col cols="4">
                  <InputView
                    label="Tipo de Solicitação"
                    :value="agendamento.cliente.tipoSolicitacao"
                  />
                </v-col>
                <v-col cols="4">
                  <InputView
                    label="Data de Emissão"
                    :value="agendamento.atendimento.doneAt | format_date"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <InputView
                    label="Forma de Pagamento"
                    :value="agendamento.atendimento.tipoPagamento"
                  />
                </v-col>
                <v-col cols="3">
                  <InputView
                    label="Valor Pago"
                    :value="agendamento.atendimento.valorPago"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-divider></v-divider>
          <v-card>
            <v-card-title
              class="text-subtitle-1 text-uppercase font-weight-medium"
            >
              Ultimas alterações
            </v-card-title>
            <v-card-text>
              <v-row
                v-for="(log, index) in agendamento.update_log.filter(
                  (e) => e.updated_at || false
                )"
                :key="index"
              >
                <v-col cols="4">
                  <InputView
                    label="Data/Hora"
                    :value="log.updated_at | format_date"
                  />
                </v-col>
                <v-col cols="3">
                  <InputView label="Por:" :value="log.updated_by.nome" />
                </v-col>
              </v-row>
              <v-row v-if="agendamento.created_at">
                <v-col cols="4">
                  <InputView
                    label="Criado em:"
                    :value="agendamento.created_at | format_date"
                  />
                </v-col>
                <v-col cols="3">
                  <InputView
                    label="Por:"
                    :value="agendamento.created_by.nome"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InputView from "../components/inputView.vue";

export default {
  name: "TicketIssued",
  components: { InputView },

  data() {
    return {
      agendamento: null,
    };
  },
  methods: {
    async carregaAgendamento() {
      const fetchedId = this.$route.params.id;
      const retorno = await axios.get("/agendamento/" + fetchedId);
      this.agendamento = retorno.data;
    },
  },
  mounted() {
    this.carregaAgendamento();
  },
};
</script>
