<template>
  <v-container>
    <v-card width="400" class="mx-auto mt-5 mt-15" :loading="loading">
      <v-form ref="form">
        <v-card-title
          class="d-flex flex-column align-center justify-center pb-0"
        >
          <h1 class="mb-6">Login</h1>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Email"
            prepend-icon="mdi-account-circle"
            v-model="email"
            :rules="[(v) => !!v || 'Esse campo é obrigatório']"
          />
          <v-text-field
            :type="showPassword ? 'text' : 'password'"
            label="Senha"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            v-model="senha"
            :rules="[(v) => !!v || 'Esse campo é obrigatório']"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="logar">Login</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "FormLogin",
  data: () => ({
    email: "",
    senha: "",
    loading: false,
    showPassword: false,
  }),

  methods: {
    ...mapActions("auth", ["login"]),
    async logar() {
      const formValido = this.$refs.form.validate();
      if (!formValido) {
        return false;
      }

      this.loading = true;
      try {
        await this.login({
          email: this.email,
          password: this.senha,
        });
        this.loading = false;
        this.$router.push("/");
      } catch (error) {
        this.$noty.error(error);
        this.loading = false;
      }
    },
  },
};
</script>
