export default {
  namespaced: true,
  state: {
    productsAccount: [
      {
        produto: "e-CPF A1",
        valor: 0,
      },
      {
        produto: "e-CPF A3 de 1 ano",
        valor: 0,
      },
      {
        produto: "e-CPF A3 de 3 anos",
        valor: 0,
      },
      {
        produto: "e-CNPJ A1",
        valor: 0,
      },
      {
        produto: "e-CNPJ A3 de 1 ano",
        valor: 0,
      },
      {
        produto: "e-CNPJ A3 de 3 anos",
        valor: 0,
      },
      {
        produto: "token",
        valor: 0,
      },
      {
        produto: "Leitora",
        valor: 0,
      },
      {
        produto: "Cartão",
        valor: 0,
      },
    ],
  },
};
