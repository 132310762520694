<template>
  <v-card :loading="loadingCard">
    <v-card-title>
      Tabela de preço
      <v-spacer></v-spacer>
      <v-menu bottom left v-if="dataToShow == this.priceTableUpdate">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="actionTable">
            <v-list-item-title>Recarregar Tabela de Produtos</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">#</th>
              <th class="text-left">Produto</th>
              <th class="text-left">Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataToShow" :key="item.produto">
              <td>{{ index + 1 }}</td>
              <td>{{ item.produto }}</td>
              <td>
                <v-text-field
                  v-model="item.valor"
                  v-currency="{ currency: 'BRL', locale: 'pt' }"
                  @change="tabelaPrice(item)"
                  dense
                  class="mt-2"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    actionTable: Function,
    priceTabela: Array,
    priceTableUpdate: Array,
    zeroedDataTable: Array,
    loadingCard: Boolean,
  },

  methods: {
    tabelaPrice(item) {
      const localPriceTabela = [...this.priceTabela];
      const index = localPriceTabela.findIndex(
        (el) => el.produto === item.produto
      );
      if (index !== -1) {
        localPriceTabela.splice(index, 1, item);
      } else {
        localPriceTabela.push(item);
      }
      this.$emit("price-tabela", localPriceTabela);
    },
  },
  computed: {
    dataToShow() {
      return this.priceTableUpdate
        ? this.priceTableUpdate
        : this.zeroedDataTable;
    },
  },
};
</script>
