<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-text-field
        label="CEP"
        required
        v-mask="['########']"
        :loading="loadingCep"
        v-model="value.cep"
        @input="input"
      >
        <template #append-outer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                outlined
                color="primary"
                :loading="loadingCep"
                @click="searchCep"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Pesquise por CEP</span>
          </v-tooltip>
        </template>
      </v-text-field></v-col
    >
    <v-col cols="6">
      <v-text-field
        label="Bairro"
        required
        v-model="value.Bairro"
        @input="input"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="9">
      <v-text-field
        label="Endereço"
        required
        v-model="value.Logradouro"
        @input="input"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field
        label="Numero"
        required
        v-model="value.Numero"
        @input="input"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field
        label="Cidade"
        required
        v-model="value.Cidade"
        @input="input"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field
        label="UF"
        required
        v-model="value.UF"
        @input="input"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field
        label="Complemento"
        required
        v-model="value.Complemento"
        @input="input"
      ></v-text-field>
    </v-col>

    <!-- <v-col cols="12" md="6">
        <v-text-field
          label="Codigo IBGE Municipio"
          required
          
          v-model="value.CodigoIbgeMunicipio"
          @input="input"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Codigo IBGE UF"
          required
          
          v-model="value.CodigoIbgeUF"
          @input="input"
        ></v-text-field>
      </v-col> -->
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      loadingCep: false,
    };
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          cep: "",
          Logradouro: "",
          Numero: "",
          Bairro: "",
          Complemento: "",
          UF: "",
          Cidade: "",
          CodigoIbgeMunicipio: "",
          CodigoIbgeUF: "",
        };
      },
    },
  },
  methods: {
    async searchCep() {
      this.loadingCep = true;
      try {
        const response = await fetch(
          `https://viacep.com.br/ws/${this.value.cep}/json`
        );
        if (!response.ok) {
          throw new Error("Erro ao buscar CEP");
        }
        const cep = await response.json();
        if (cep.erro === true) {
          return this.$noty.error("cep não encontrado");
        }
        this.value.Logradouro = cep.logradouro;
        this.value.Bairro = cep.bairro;
        this.value.UF = cep.uf;
        this.value.Cidade = cep.localidade;
      } catch (error) {
        console.error(error);
        this.$noty.error("error" + error);
      } finally {
        this.loadingCep = false;
      }
    },
    input() {
      this.$emit("input", this.value);
    },
  },
};
</script>
