//import LogRocket from 'logrocket';
import jwtDecode from "jwt-decode";
import openReplay from "../plugins/openreplay";
import localForage from "localforage";

export default {
  namespaced: true,
  state: {
    usuarioLogado: null,
    status: "",
    token: localStorage.getItem("token") || null,
    user: {},
    menssagem: "",
    roles: "",
    tokenDecode: null,
  },
  getters: {
    isAdmin(state, getters) {
      if (getters.usuarioLogado) {
        return getters.usuarioLogado.role.toUpperCase() === "ADMIN" || false;
      } else {
        return false;
      }
    },

    hasRole: (state, getters) => (roles) => {
      if (!getters.usuarioLogado) return false;

      if (getters.isAdmin) return true;

      const userRole = getters.usuarioLogado.role.toUpperCase();

      return (
        roles != undefined &&
        roles.some((el) => {
          return el == userRole || el == "*";
        })
      );
    },

    usuarioLogado(state) {
      try {
        state.usuarioLogado = localStorage.getItem("userSession");
        return JSON.parse(state.usuarioLogado);
      } catch (err) {
        return (state.usuarioLogado = null);
      }
    },

    token: (state) => {
      try {
        state.token = localStorage.getItem("token");
        state.tokenDecode = jwtDecode(state.token);
        return state.token;
      } catch (err) {
        return (state.token = null);
      }
    },
    isLoggedIn: (state, getters) => {
      return !!getters.token;
    },
    authStatus: (state) => state.status,
    menssagem: (state) => state.menssagem,
  },
  mutations: {
    async setUsuarioLogado(state, { user, token }) {
      state.usuarioLogado = user;
      await localStorage.setItem("userSession", JSON.stringify(user));
      await localStorage.setItem("token", token);

      openReplay.setUserID(state.usuarioLogado.email);
      openReplay.setMetadata("name", state.usuarioLogado.nome);
      // LogRocket.identify(state.usuarioLogado.email, {
      //     name: state.usuarioLogado.nome,
      //     email: state.usuarioLogado.email,
      // });
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token, user) {
      state.status = "success";
      state.token = token;
      state.user = user;
      state.usuarioLogado = user;
    },
    auth_error(state, msg) {
      state.status = "error";
      state.menssagem = msg;
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
  },
  
  actions: {
    async login({ commit }, login) {
      commit("auth_request");
      try {
        const resp = await axios.post("/login", login);
        const { token, usuario: user } = resp.data;

        commit("auth_success", { token, user });
        commit("setUsuarioLogado", { user, token });

        localStorage.setItem("token", token);

        return { user, token };
      } catch (err) {
        const errorMessage = err.response.data.message || "Erro no login";
        commit("auth_error", errorMessage);
        localStorage.removeItem("token");
        throw errorMessage;
      }
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("userSession");
        localStorage.clear();
        localForage.clear();
        resolve();
      });
    },
  },
};
