<template>
  <div class="d-flex flex-row">
    <div v-if="resultCheckCpfCnpj != null">
      <v-expand-x-transition>
        <v-alert
          dense
          text
          type="success"
          v-if="resultCheckCpfCnpj.Codigo === 0"
        >
          o CPF/CNPJ: <strong>{{ resultCheckCpfCnpj.Mensagem }}</strong> foi
          verificado com
          <strong>Sucesso</strong>
        </v-alert>
        <v-alert dense outlined type="error" v-else>
          erro consulta <strong>{{ resultCheckCpfCnpj.Mensagem }}</strong>
        </v-alert>
      </v-expand-x-transition>
    </div>

    <v-menu open-on-hover offset-y left>
      <template #activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="mx-3">
          <v-icon>mdi-alert-octagon-outline</v-icon>
        </v-btn>
      </template>
      <v-card width="250">
        <v-card-title>
          <v-spacer></v-spacer>
          Atenção <v-icon rigth>mdi-alert</v-icon>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="justify">
          <div>
            É necessário realizar uma consulta para confirmar os dados do CPF ou
            CNPJ antes de efetuar a captura do protocolo junto ao fornecedor
            Safeweb.
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-btn color="success" :loading="loadingBtn" @click="validarCpfCnpj"
      ><v-icon left>mdi-text-box-search-outline</v-icon>Consulta CPF/CNPJ</v-btn
    >
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    infoClient: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      resultCheckCpfCnpj: null,
      loadingBtn: false,
    };
  },
  methods: {
    ...mapActions("safeweb", ["fetchPreCheckCpfCnpj"]),
    async validarCpfCnpj() {
      const cpfcnpj = this.formatCaracteresCpfCnpj(this.infoClient.cpfcnpj);

      this.loadingBtn = true;
      const clientCpf = {
        CPF: cpfcnpj,
        DocumentoTipo: "1",
        DtNascimento: this.formatDataNascimento(this.infoClient.dataNascimento),
      };
      const clientCnpj = {
        CPF: this.formatCaracteresCpfCnpj(this.infoClient.cpfTitular),
        DocumentoTipo: "2",
        DtNascimento: this.formatDataNascimento(
          this.infoClient.dataNascimentoTitular
        ),
        CNPJ: cpfcnpj,
      };
      const client = cpfcnpj.length < 12 ? clientCpf : clientCnpj;
      try {
        const { data } = await this.fetchPreCheckCpfCnpj(client);
        this.resultCheckCpfCnpj = data;
      } catch (error) {
        this.$noty.error("error no servidor, tente novamente mais tarde...");
        console.error(error);
      } finally {
        this.loadingBtn = false;
      }
    },
    formatDataNascimento(value) {
      if (!value) {
        return "";
      } else {
        var partes = value.split("/");
        return partes[2] + "-" + partes[1] + "-" + partes[0];
      }
    },
    formatCaracteresCpfCnpj(value) {
      if (!value) {
        return "";
      } else {
        return value.replace(/\D/g, "");
      }
    },
  },
};
</script>
