<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="72">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Hoje
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <div class="mr-2">
            <MenuPsbio />
          </div>
          <MenuFilters>
            <v-row>
              <v-col cols="9">
                <v-select
                  label="Filtrar por agenda"
                  v-model="agendaSelecionada"
                  :items="listAgendas"
                  item-text="nome"
                  return-object
                  outlined
                  dense
                  clearable
                  hide-details
                  class="d-flex"
                >
                  <template v-slot:item="{ item }">
                    <v-badge inline left :color="item.cor"></v-badge>
                    {{ item.nome }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Diario</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Semana</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Mensal</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                      <v-list-item-title>4 dias</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  dense
                  v-model="cpfcnpj"
                  @change="filterCpfCnpj"
                  label="Pesquisa por cpf/cnpj"
                >
                  <template v-slot:append>
                    <v-btn
                      class="mb-1"
                      :loading="loadingTextField"
                      x-small
                      text
                      plain
                      color="indigo"
                      @click="filterCpfCnpj"
                      >Localizar</v-btn
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  label="Filtro por usuário que criou a agenda"
                  v-model="idUSerSelected"
                  :items="listUser"
                  item-text="nome"
                  item-value="_id"
                  outlined
                  dense
                  clearable
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
          </MenuFilters>
          <div class="ml-5">
            <form-agendamento ref="formAgendamento"></form-agendamento>
          </div>
          <!-- dialogo form -->
        </v-toolbar>
      </v-sheet>
      <v-sheet>
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="eventosFiltradosPorCriador"
          :type="type"
          event-overlap-mode="column"
          intervalMinutes="20"
          first-time="8:00"
          interval-count="36"
          interval-height="55"
          :short-intervals="false"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="loadData"
        >
          <template v-slot:event="{ event }">
            <div
              :class="{
                'text-decoration-line-through':
                  event.dados.status === 'REALIZADO',
              }"
            >
              <strong class="d-block"
                >{{ event.dados.cliente.tipoAgenda }} ::
                {{ event.dados.agenda.nome }} -
                {{ formatData("HH:mm", event.start) }}</strong
              >
              <span>
                <v-icon
                  v-if="event.dados.cliente?.pagamento?.status == 'true'"
                  color="light-green accent-3"
                  >mdi-receipt-text-check-outline</v-icon
                >
                <v-icon
                  v-else-if="event.dados.cliente.pagamento?.comprovante"
                  color="white"
                  >mdi-receipt-text-outline</v-icon
                >
              </span>
              <span v-if="event.dados.status === 'CANCELADO'">[Cancelado]</span>
              <span> {{ event.dados.cliente.nome }}</span>
            </div>
          </template>
        </v-calendar>
        <!-- card menu -->
        <v-navigation-drawer
          width="400"
          right
          app
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
        >
          <v-card color="dark" min-width="350px" flat>
            <v-toolbar color="blue lighten-2" dark>
              <v-btn icon @click="selectedOpen = false" title="Fechar">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Agendamento</v-toolbar-title>

              <v-spacer></v-spacer>
              <v-btn
                icon
                title="Editar"
                v-if="isAtivo && this.hasRole(['APOIO', 'ATENDIMENTO'])"
                @click="editarAgendamento"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                title="Deletar"
                v-if="isAtivo && this.hasRole(['APOIO'])"
                @click="dialogDelete = true"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text v-if="selectedOpen">
              <p class="mb-3">
                <strong
                  >{{ selectedEvent.dados.cliente.tipoAgenda }} ::
                  {{ selectedEvent.dados.agenda.nome }}</strong
                >
              </p>
              <p>
                Cliente:
                <strong>{{ selectedEvent.dados.cliente.nome }} </strong>
              </p>
              <p v-if="selectedEvent.dados.cliente.arquivoCNH">
                Documento:
                <a
                  @click="
                    downloadFile('docs', selectedEvent.dados.cliente.arquivoCNH)
                  "
                  href="#"
                  >Abrir Arquivo</a
                >
              </p>
              <p v-if="selectedEvent.dados.cliente.arquivoSocial">
                C. Social:
                <a
                  @click="
                    downloadFile(
                      'docs',
                      selectedEvent.dados.cliente.arquivoSocial
                    )
                  "
                  href="#"
                  >Abrir Arquivo</a
                >
              </p>
              <p>CPF/CNPJ: {{ selectedEvent.dados.cliente.cpfcnpj }}</p>
              <p>Telefone: {{ selectedEvent.dados.cliente.telefone }}</p>
              <p>Email: {{ selectedEvent.dados.cliente.email }}</p>
              <p>Contador: {{ selectedEvent.dados.cliente.belongsTo.name }}</p>
              <p>Produto: {{ selectedEvent.dados.cliente.produtoEmissao }}</p>
              <p>Ticket: {{ selectedEvent.dados.cliente.ticket }}</p>
              <p>
                Pedido do Site: {{ selectedEvent.dados.cliente.sitePedido }}
              </p>
              <p>Atendente: {{ selectedEvent.dados.cliente.atendente }}</p>
              <p>
                Consultor: {{ selectedEvent.dados.cliente.belongsTo.consultor }}
              </p>
              <p>Observações: {{ selectedEvent.dados.cliente.observacoes }}</p>
              <p>
                Valor Pago: {{ selectedEvent.dados.cliente.pagamento?.valor }}
              </p>
              <p>
                Data/Hora agendamento:
                {{ selectedEvent.dados.dataHora | format_date }}
              </p>
              <div v-if="selectedEvent.dados.cliente.pagamento?.comprovante">
                Comprovante de Pagamento:
                <a
                  @click="
                    downloadFile(
                      'comprovantes',
                      selectedEvent.dados.cliente.pagamento?.comprovante
                    )
                  "
                  href="#"
                  >Abrir Arquivo</a
                >
                <p>
                  <v-btn-toggle
                    color="primary"
                    v-model="selectedEvent.dados.cliente.pagamento.status"
                    tile
                    @change="updateFileStatus"
                  >
                    <v-btn value="true"> Confirmado </v-btn>
                    <v-btn value="false"> Pendente </v-btn>
                  </v-btn-toggle>
                </p>
              </div>

              <div v-if="selectedEvent.dados.update_log">
                <p><strong> Ultimas alterações</strong></p>
                <p
                  v-for="(log, index) in selectedEvent.dados.update_log
                    .filter((e) => e.updated_at || false)
                    .reverse()"
                  :key="index"
                >
                  {{ log.updated_at | format_date }}
                  por
                  {{ log.updated_by.nome }}
                </p>
              </div>
              <div v-if="selectedEvent.dados.created_at">
                <p>
                  Criado em:
                  {{ selectedEvent.dados.created_at | format_date }}
                  por
                  {{ selectedEvent.dados.created_by.nome }}
                </p>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="isAtivo && this.hasRole(['APOIO', 'ATENDIMENTO'])"
                text
                color="primary"
                @click="iniciarAtendimento"
              >
                Iniciar Atendimento
              </v-btn>
              <v-btn v-else text color="primary" @click="exibirEmissao">
                Exibir Emissão
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-navigation-drawer>
      </v-sheet>

      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-col>

    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Confirmar </v-card-title>
        <v-card-text
          >Tem certeza que deseja remover esse agendamento?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogDelete = false" text> Cancelar </v-btn>
          <v-btn color="red darken-1" text @click="deletarAgendamento">
            Deletar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
//import { DateTime } from "luxon";
import FormAgendamento from "../components/FormAgendamento.vue";
import MenuFilters from "../components/account/MenuFilters.vue";
import MenuPsbio from "../components/Safeweb/Psbio.vue";

import { mapState, mapGetters } from "vuex";

import { DateTime } from "luxon";

export default {
  name: "agenda-calendario",

  components: {
    FormAgendamento,
    MenuFilters,
    MenuPsbio,
  },

  data: () => ({
    loading: false,
    loadingTextField: false,
    ready: false,
    cpfcnpj: "",
    dialogDelete: false,
    focus: "",
    type: "day",
    typeToLabel: {
      month: "Mensal",
      week: "Semana",
      day: "Diário",
      "4day": "4 Dias",
    },
    selectedEvent: {},
    listUser: {},
    selectedElement: null,
    selectedOpen: false,

    // agendas: [],
    agendaSelecionada: null,
    idUSerSelected: null,
    loadingUpdate: false,
  }),

  computed: {
    eventosFiltradosPorCriador() {
      const e =
        this.idUSerSelected === null
          ? this.listEventos(this.agendaSelecionada)
          : this.listFilterCreated(this.idUSerSelected);
      return e;
    },
    ...mapState({
      //events: (state) => state.agendamento.eventos,
      agendas: (state) => state.agendamento.agendas,
    }),
    ...mapGetters({ listAgendas: "agendamento/listAgendas" }),
    ...mapGetters("agendamento", ["listEventos", "listFilterCreated"]),
    ...mapGetters({ userSession: "auth/usuarioLogado" }),
    ...mapGetters({ hasRole: "auth/hasRole" }),

    isAtivo() {
      return (
        this.selectedEvent.dados &&
        this.selectedEvent.dados.status &&
        this.selectedEvent.dados.status !== "REALIZADO"
      );
    },
  },

  mounted() {
    //this.$refs.calendar.checkChange();
    this.ready = true;
    this.loadUSer();
  },
  methods: {
    async filterCpfCnpj() {
      this.loadingTextField = true;
      try {
        const { data } = await axios.get("/agendamento-filter", {
          params: {
            cpfcnpj: this.cpfcnpj,
          },
        });
        const sortedData = data.sort(
          (a, b) => new Date(b.dataHora) - new Date(a.dataHora)
        );
        this.selectedEvent.dados = sortedData[0];
        this.selectedOpen = true;
      } catch (err) {
        this.$noty.error(err.response.data.error);
        console.log(err);
      } finally {
        this.loadingTextField = false;
        this.cpfcnpj = "";
      }
    },

    async loadUSer() {
      try {
        const result = await axios.get("/user");
        this.listUser = result.data;
      } catch (error) {
        console.error(error);
      }
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.agenda.cor ?? "blue";
    },
    setToday() {
      this.focus = "";
      this.type = "day";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      if (event.dados.status === "CANCELADO") {
        return false;
      }

      let canEdit = true;
      if (!this.hasRole(["PARCEIRO"])) {
        canEdit = false;
      }

      if (
        Array.isArray(this.userSession.agendaSelecionada) &&
        this.userSession.agendaSelecionada.includes(event.dados.agenda._id)
      ) {
        canEdit = true;
      }

      if (event.dados.created_by._id == this.userSession._id) {
        canEdit = true;
      }

      if (!canEdit) {
        return false;
      }

      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    /**
     *
     */
    async fetchData(periodo) {
      this.loading = true;
      await this.$store.dispatch("agendamento/fetchAllEvents", {
        //  agenda: this.agendaSelecionada,
        periodo: periodo,
      });
      this.loading = false;
    },

    /**
     *
     */
    iniciarAtendimento() {
      const atendimento = this.selectedEvent;
      this.$router.push({
        name: "Atendimento",
        params: { id: atendimento.dados._id },
      });
    },

    exibirEmissao() {
      const ticket = this.selectedEvent;
      this.$router.push({
        name: "TicketIssued",
        params: { id: ticket.dados._id },
      });
    },

    /**
     *
     */
    async deletarAgendamento() {
      this.dialogDelete = false;
      this.selectedOpen = false;

      const atendimento = this.selectedEvent.dados;
      try {
        await axios.delete("/agendamento/" + atendimento._id, atendimento);
        this.$store.commit("agendamento/delEvento", atendimento);

        this.$noty.success("Removido com sucesso");
      } catch (err) {
        console.log(err);
        this.$noty.error("Oops, something went wrong!");
      }
    },

    filtrarAgenda(val) {
      console.log(val);
    },

    /**
     *
     */
    editarAgendamento() {
      const atendimento = JSON.parse(JSON.stringify(this.selectedEvent.dados));

      if (!atendimento.cliente.belongsTo) {
        atendimento.cliente.belongsTo = {
          name: atendimento.cliente.contadorNome || "",
          phoneNumber: atendimento.cliente.contadorFone || "",
          consultor: atendimento.cliente.consultor || "",
        };
      }

      if (!atendimento.cliente.pagamento) {
        atendimento.cliente.pagamento = {
          valor: atendimento.cliente.pagamento || "",
        };
      }

      this.$refs["formAgendamento"].openDialog = true;
      this.$refs["formAgendamento"].cadastro = atendimento.cliente;
      this.$refs["formAgendamento"].agendaSelecionada = atendimento.agenda;
      this.$refs["formAgendamento"].editedId = atendimento._id;
      this.$refs["formAgendamento"].dataHora = atendimento.dataHora;
      this.$refs["formAgendamento"].tempPhone = atendimento.cliente.telefone;
      this.$refs["formAgendamento"].selectedProduto =
        atendimento.produtoSelecionado;
      this.$refs["formAgendamento"].contaSelecionado =
        atendimento.cliente.belongsTo;

      const cliente = this.$store.state.clientes.clientes.find(
        (el) => el._id == atendimento.cliente.client_id
      );

      if (cliente) {
        this.$refs["formAgendamento"].clienteSelecionado = cliente;
      } else {
        this.$refs["formAgendamento"].clienteSelecionado = {
          name: atendimento.cliente.nome,
        };
      }
    },

    formatData(format, date) {
      const data = DateTime.fromJSDate(date);
      return data.isValid ? data.toFormat(format) : "";
    },

    async updateFileStatus() {
      this.loadingUpdate = true;

      const dados = {
        id: this.selectedEvent.dados._id,
        status: this.selectedEvent.dados.cliente.pagamento.status,
      };

      await axios.post("/pagamento-status", dados);
      this.loadingUpdate = false;
    },

    async downloadFile(bucket, fileId) {
      // const fileId = this.selectedEvent.dados.cliente.pagamento?.comprovante;
      const { data, headers } = await axios.get(`/files/${bucket}/${fileId}`, {
        responseType: "blob",
      });

      // const filename = fileId + "_" + headers.filename;
      var fileURL = window.URL.createObjectURL(
        new Blob([data], { type: headers["content-type"] })
      );
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.target = "_blank";
      // fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    loadData(val) {
      let periodo = [val.start.date, val.end.date];

      this.fetchData(periodo);
    },
  },

  created() {
    /* colocado em cache */

    this.$store.dispatch("fetchAllProdutos");
    this.$store.dispatch("carregarUsuarios");
    this.$store.dispatch("agendamento/fetchAgendas");
    //this.$store.dispatch("clientes/fetchAllClientes");
    // this.$store.dispatch("account/fetchAllAccounts");
  },
};
</script>

<style>
.v-event-timed {
  padding: 4px !important;
}
</style>
