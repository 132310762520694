import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/es5/locale/pt";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.blue,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        colorDev: colors.yellow.darken3,
      },
      dark: {
        primary: colors.blue.lighten1,
        colorDev: colors.purple,
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
