<template>
  <v-sheet>
    <h2>Atendimento de Cliente</h2>

    <v-form ref="form" lazy-validation>
      <v-container>
        <h3>Dados do cliente</h3>
        <v-divider class="mb-4"></v-divider>
        <v-row>
          <v-col cols="12" md="4">
            <InputPesquisaCnpj
              v-model="cadastro.cpfcnpj"
              @cnpj-data="handleCnpjData"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="Nome do Cliente*"
              required
              :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              v-model="cadastro.nome"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :items="['Novo Certificado', 'Renovação']"
              label="Tipo de Solicitação"
              outlined
              v-model="cadastro.tipoSolicitacao"
              :rules="[(v) => !!v || 'Esse campo é obrigatório']"
            ></v-select>
          </v-col>

          <v-col cols="12" md="4" v-if="requirementCnpj">
            <v-text-field
              label="Nome do titular do CNPJ"
              required
              v-model="cadastro.nomeTitular"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" v-if="requirementCnpj">
            <v-text-field
              label="CPF titular do CNPJ"
              v-mask="['###.###.###-##']"
              required
              v-model="cadastro.cpfTitular"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" v-if="requirementCnpj">
            <v-text-field
              label="Data nascimento titular CNPJ"
              v-mask="['##/##/####']"
              persistent-hint
              hint="Preencha data DD/MM/AAAA"
              v-model="cadastro.dataNascimentoTitular"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              label="Data nascimento"
              v-mask="['##/##/####']"
              persistent-hint
              hint="Preencha data DD/MM/AAAA"
              v-model="cadastro.dataNascimento"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <vue-tel-input-vuetify
              v-model="cadastro.telefone"
              label="Telefone*"
              :rules="[telefoneRule]"
              defaultCountry="BR"
              :preferredCountries="['BR', 'AR', 'UY', 'PY']"
              :disabledFetchingCountry="true"
              placeholder=""
              @input="onPhoneValidate"
            ></vue-tel-input-vuetify>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="Email*"
              v-model="cadastro.email"
              required
              :rules="[(v) => !!v || 'Esse campo é obrigatório']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <ConsultaPrevia :infoClient="cadastro" />
          </v-col>
        </v-row>

        <h3 class="mt-4">Endereço:</h3>
        <v-divider class="mb-4"></v-divider>
        <v-row>
          <v-col cols="12">
            <AddressSafeweb v-model="cadastro.endereco" />
          </v-col>
        </v-row>
        <h3 class="mt-4">Informações do Produto</h3>
        <v-divider class="mb-5"></v-divider>

        <v-row>
          <v-col cols="12" sm="12">
            <v-select
              :items="['Presencial', 'Videoconferencia', 'Delivery']"
              label="Tipo de Agenda"
              outlined
              v-model="cadastro.tipoAgenda"
              :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              @change="listProductsSafeweb"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="
            agendamento.ticketValid == null &&
            agendamento.protocoloSafeweb == null
          "
        >
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="safewebProduto"
              :hint="`Produto Cliente: ${productClient}`"
              persistent-hint
              :disabled="disabledProdutoSafeweb"
              :loading="loadingProdutoSafeweb"
              item-text="nomeCompletoProduto"
              label="Produto Safeweb"
              :items="allProducts"
              return-object
              @change="productSafewebSelected"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.Nome }}</v-list-item-title>
                  <v-list-item-subtitle class="text--primary">
                    Modelo: {{ item.ProdutoModelo }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Descrição: {{ item.Descricao }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <CaptureProcolSafeweb
              v-model="cadastro.ticket"
              @data-safeweb="dataSafeweb"
              @update-agendamento="updateAgendamento"
              :client="cadastro"
              :productSelected="safewebProduto"
              :tipoAgenda="cadastro.tipoAgenda"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="6">
            <v-alert
              color="success"
              border="left"
              dense
              elevation="2"
              colored-border
              icon="mdi-package-variant"
            >
              Produto:
              <strong>
                {{ cadastro.produtoEmissao }}
              </strong>
            </v-alert>
          </v-col>
          <v-col cols="6" class="d-flex flex-row justify-space-between">
            <v-alert
              color="success"
              border="left"
              dense
              elevation="2"
              colored-border
              icon="mdi-file-certificate-outline"
            >
              Ticket/Protocolo atribuído ao cliente:
              <strong>
                {{ cadastro.ticket }}
              </strong>
            </v-alert>
            <v-tooltip v-if="agendamento.protocoloSafeweb !== null" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  outlined
                  color="error"
                  :loading="loadingCancelProtocoloSafeweb"
                  @click="cancelProtocoloSafeweb"
                  fab
                  small
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Cancelar protocolo.</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <div v-if="cadastro.urlHope">
              <a :href="cadastro.urlHope" target="_blank"
                >HOPE PRIMEIRA EMISSÃO</a
              >
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <h3 class="mt-4">Contador</h3>
            <v-divider class="mb-1"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="contaSelecionado"
                  :search-input.sync="contaNome"
                  :items="listAll.map((i) => i)"
                  item-text="name"
                  item-value="_id"
                  label="Pesquisar o contador"
                  placeholder="Começe a digitar para pesquisar"
                  prepend-icon="mdi-account-tie"
                  return-object
                  hide-no-data
                  :filter="filterCliente"
                  cache-items
                  @change="onChangeAccount"
                  clearable
                  :loading="loadingAccount"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                >
                  <!-- <template v-slot:item="{ item, parent }">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="parent.genFilteredText(item.name)"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    >CPF/CNPJ:
                    <span v-html="parent.genFilteredText(item.cpfcnpj)"></span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template> -->
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-html="item.name"></v-list-item-title>
                      <v-list-item-subtitle
                        >CPF/CNPJ:
                        <span v-html="item.cpfcnpj"></span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="6">
                <vue-tel-input-vuetify
                  v-model="cadastro.belongsTo.phoneNumber"
                  label="Telefone do Contador"
                  defaultCountry="BR"
                  :preferredCountries="['BR', 'AR', 'UY', 'PY']"
                  :disabledFetchingCountry="true"
                  placeholder=""
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Email do Contador"
                  v-model="cadastro.belongsTo.email"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" md="6">
            <h3 class="mt-4">Informações adicionais...</h3>
            <v-divider class="mb-4"></v-divider>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Número do Pedido no Site"
                  v-model="cadastro.sitePedido"
                  disabled
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  label="Atendente Agendamento"
                  v-model="cadastro.atendente"
                  disabled
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-textarea
                  label="Observações"
                  outlined
                  v-model="cadastro.observacoes"
                  auto-grow
                  disabled
                  filled
                >
                </v-textarea>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  label="Novas Observações"
                  outlined
                  v-model="atendimento.observacoes"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <h3 class="mt-4">Pagamento</h3>
        <v-divider class="mb-4"></v-divider>

        <v-row>
          <v-col cols="6">
            <v-select
              label="Tipo de Pagamento"
              :items="[
                'Boleto Senha',
                'Boleto Site',
                'Boleto',
                'Cartão 2X',
                'Cartão 3X',
                'Cartão Crédito',
                'Cartão Débito',
                'Deposito',
                'Dinheiro',
                'Nota de Empenho',
                'PIX',
                'POS Delivery',
                'Site',
                'Valid',
                'Voucher',
              ]"
              :rules="[(v) => !!v || 'Selecione um tipo']"
              v-model="atendimento.tipoPagamento"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Valor Pago"
              v-model="atendimento.valorPago"
              v-currency="{ currency: 'BRL', locale: 'pt' }"
              :rules="[(v) => !!v || 'O campo é obrigatório']"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-btn
            color="primary"
            @click="finalizaAtendimento"
            :loading="loadingAtendimento"
            >Finalizar</v-btn
          >
          <v-spacer></v-spacer>

          <v-dialog v-model="cancelarDialog" persistent max-width="390">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" dark v-bind="attrs" v-on="on">
                Cancelar Emissão
              </v-btn>
            </template>
            <v-card :loading="loadingAtendimento">
              <v-card-title class="text-h5">
                Tem certeza que deseja cancelar o atendimento?
              </v-card-title>
              <v-card-text>Um novo atendimento deverá ser criado</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  text
                  @click="
                    cancelarDialog = false;
                    loadingAtendimento = false;
                  "
                >
                  Não
                </v-btn>
                <v-btn color="error" text @click="cancelarAtendimento">
                  Sim
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-form>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-sheet>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
//import SelectConta from "../components/account/SelectAccount.vue"
//import InputTicket from "../components/InputTicket.vue";
import CaptureProcolSafeweb from "../components/Safeweb/CaptureProcolSafeweb.vue";
import AddressSafeweb from "../components/Safeweb/Address.vue";
import ConsultaPrevia from "../components/Safeweb/ConsultaPrevia.vue";
import InputPesquisaCnpj from "../components/InputPesquisaCnpj.vue";

export default {
  name: "AtendimentoForm",
  components: {
    // InputTicket,
    CaptureProcolSafeweb,
    AddressSafeweb,
    InputPesquisaCnpj,
    ConsultaPrevia,
  },

  data() {
    return {
      loading: false,
      loadingAtendimento: false,
      editTicket: true,
      loadingProdutoSafeweb: false,
      disabledProdutoSafeweb: false,
      loadingCancelProtocoloSafeweb: false,
      safewebProduto: null,
      cancelarDialog: false,
      productClient: "",
      cadastro: {
        nome: "",
        email: "",
        telefone: "",
        cpfcnpj: "",
        atividade: "",
        fornecedor: "Safeweb",
        dataNascimentoTitular: "",
        hora: "",
        urlHope: "",
        tipoEmissao: "",
        belongsTo: {
          name: "",
        },
        endereco: {},
        produtoSelecionado: null,
      },

      atendimento: {
        valorPago: "",
        tipoPagamento: "",
      },
      contaNome: "",
      contaSelecionado: null,
      agendamento: {},
    };
  },

  watch: {
    // selectedProduto(val) {
    //   //this.cadastro.produtoEmissao = val.nome;
    //   // this.cadastro.produtoSelecionado = val
    // },
    // cadastro(val) {
    //   if (val.produtoSelecionado == null) {
    //     let prod = this.produtos.find(
    //       (el) => el.nome.trim() == val.produtoEmissao.trim()
    //     );
    //     this.cadastro.produtoSelecionado = prod;
    //     console.log(prod, this.cadastro.produtoEmissao);
    //   }
    // },
  },
  methods: {
    ...mapActions("safeweb", ["fetchProductsSafeweb", "cancelProcol"]),
    async finalizaAtendimento() {
      const formValido = this.$refs.form.validate();

      if (!formValido) {
        return false;
      }
      this.loadingAtendimento = true;
      this.agendamento.cliente = this.cadastro;
      this.agendamento.atendimento = this.atendimento;

      try {
        await axios.post("/atendimento", this.agendamento);
        this.$noty.success("Finalizado com sucesso");
        this.$router.push({
          name: "TicketIssued",
          params: { id: this.agendamento._id },
        });
      } catch (err) {
        this.$noty.error(err.response.data.erro);
      } finally {
        this.loadingAtendimento = false;
      }
    },

    async cancelarAtendimento() {
      let idAtendimento = this.agendamento._id;
      this.loadingAtendimento = true;

      try {
        await axios.post(
          `/atendimento/${idAtendimento}/cancelar`,
          this.agendamento
        );
        this.$noty.success("Cancelado com sucesso");
        this.$router.push("/");
      } catch (err) {
        this.$noty.error(err.response.data.erro);
      } finally {
        this.cancelarDialog = false;
        this.loadingAtendimento = false;
      }
    },

    async listProductsSafeweb() {
      this.loadingProdutoSafeweb = true;
      this.disabledProdutoSafeweb = true;

      const tipoAgenda = {
        Videoconferencia: 3,
        Presencial: 1,
        Delivery: 1,
      };
      const idTipoEmissao = tipoAgenda[this.cadastro.tipoAgenda] || null;

      try {
        const CnpjAR = "35646780000126";
        await this.fetchProductsSafeweb({ idTipoEmissao, CnpjAR });
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingProdutoSafeweb = false;
        this.disabledProdutoSafeweb = false;
      }
    },

    productSafewebSelected(e) {
      this.cadastro.produtoEmissao = e.nomeCompletoProduto;
    },
    dataSafeweb(e) {
      this.cadastro.urlHope = e.url;
    },
    async cancelProtocoloSafeweb() {
      this.loadingCancelProtocoloSafeweb = true;
      const dados = {
        idAgenda: this.$route.params.id,
        Protocolo: this.cadastro.ticket,
        CnpjAR: "35646780000126",
      };
      try {
        await this.cancelProcol({ dados });
        this.$noty.success("Cancelado protocolo");
        this.cadastro.ticket = "";
        this.cadastro.fornecedor = "Safeweb";
        this.cadastro.urlHope = "";
        this.agendamento = {};
      } catch (error) {
        console.error(error);
        const { data } = error.response;
        this.$noty.error(data.error.Message);
      } finally {
        this.loadingCancelProtocoloSafeweb = false;
      }
    },

    updateAgendamento() {
      // this.carregaAgendamento();
      this.agendamento.protocoloSafeweb = this.cadastro.ticket;
    },

    async carregaAgendamento() {
      const fetchedId = this.$route.params.id;

      const retorno = await axios.get("/agendamento/" + fetchedId);

      this.agendamento = retorno.data;
      if (!this.cadastro.belongsTo) {
        this.cadastro.belongsTo = {
          name: "",
        };
      }
      this.cadastro = retorno.data.cliente;
      this.productClient = retorno.data.cliente.produtoEmissao;

      this.contaSelecionado = this.cadastro.belongsTo;

      delete this.agendamento.cliente;

      if (this.agendamento.ticketValid != null) {
        this.cadastro.ticket = this.agendamento.ticketValid.ticket_number;
      }
      if (this.agendamento.protocoloSafeweb != null) {
        this.cadastro.ticket = this.agendamento.protocoloSafeweb.protocolo;
        this.cadastro.fornecedor = this.agendamento.protocoloSafeweb.vendor;
        this.cadastro.urlHope =
          this.agendamento.protocoloSafeweb.urlHopeVideoConferencia;
        this.cadastro.produtoEmissao =
          this.agendamento.protocoloSafeweb.produto;
      }
    },

    handleCnpjData(data) {
      if (!this.cadastro.endereco) {
        this.cadastro.endereco = {};
      }
      this.cadastro.endereco.cep = data.log_cep;
      this.cadastro.endereco.Bairro = data.log_bairro;
      this.cadastro.endereco.Logradouro = data.log_nome;
      this.cadastro.endereco.Numero = data.log_num;
      this.cadastro.endereco.Cidade = data.log_municipio;
      this.cadastro.endereco.UF = data.log_uf;
      this.cadastro.endereco.Complemento = data.log_comp;
      this.cadastro.nome = data.razao;
      // this.cadastro.email = data.email;
      //this.cadastro.nomeTitular = data["0"].socios_nome;
    },

    copyTicket() {
      this.atendimento.ticket = this.cadastro.ticket;
      this.$forceUpdate();
    },

    onChangeAccount(e) {
      //if (!e) return false;

      if (typeof e === "object" && e !== null) {
        this.cadastro.belongsTo = e;
        return;
      }

      this.cadastro.belongsTo = {
        name: e,
        phoneNumber: "",
        email: "",
      };
    },

    onPhoneValidate(formattedNumber, { number, valid }) {
      this.cadastro.telefone = number.international;
      this.tempPhone = number.national;

      if (!valid) {
        //
      }
    },
    ticketValidation() {
      let ticketRules = [];
      if (this.cadastro.ticket != ticketRules) {
        ticketRules.push((v) => /[0-9]{11}/.test(v) || "Necessário 11 números");
      }
      return ticketRules;
    },

    filterCliente(item, queryText) {
      const cpf = queryText.replace(/[^0-9]/g, "");
      if (
        cpf.length > 0 &&
        item.cpfcnpj.replace(/[^0-9]/g, "").indexOf(cpf) > -1
      ) {
        return true;
      }

      if (
        item.name &&
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      ) {
        return true;
      }
    },
  },

  computed: {
    ...mapState({
      // produtos: (state) => state.todosProdutos,
      listUsuarios: (state) => state.usuarios.map((v) => v.nome),
      loadingAccount: (state) => state.account.loadingAll,
    }),
    ...mapGetters("account", ["listAll"]),
    ...mapGetters("safeweb", ["allProducts"]),
    consultor: {
      get() {
        if (this.cadastro.consultor) {
          return this.cadastro.consultor;
        }

        if (this.clienteSelecionado?.belongsTo?.consultor) {
          return this.clienteSelecionado.belongsTo.consultor;
        }
        return this.cadastro.belongsTo && this.cadastro.belongsTo.consultor
          ? this.cadastro.belongsTo.consultor
          : null;
      },
      set(valor) {
        this.cadastro.belongsTo.consultor = valor;
      },
    },
    requirementCnpj() {
      const cleanedValue = this.cadastro.cpfcnpj.replace(/\D/g, "");
      if (cleanedValue.length < 12) {
        return false;
      } else {
        return true;
      }
    },
    telefoneRule() {
      return (v) => {
        const telefoneRegex = /\+\d{2} \d{2} \d{4,5}-\d{4}/;
        if (!v || telefoneRegex.test(v)) {
          return true;
        }
        return "Formato de telefone inválido";
      };
    },
  },

  async created() {
    await this.carregaAgendamento();
    await this.listProductsSafeweb();
    this.$store.dispatch("fetchAllProdutos");
    this.$store.dispatch("carregarUsuarios");
    this.$store.dispatch("clientes/fetchAllClientes");
    this.$store.dispatch("account/fetchAllAccounts");
  },
};
</script>

<style scoped></style>
