<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="6">
        <template>
          <v-card ref="form" :loading="loading">
            <v-card-text>
              <v-select
                label="Pesquise tipo do cliente"
                :items="allTypeClient"
                item-text="nome"
                item-value="type"
                multiple
                v-model="typeSelected"
              >
              </v-select>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="mt-4"
                :loading="loading"
                @click="searchClient"
                :disabled="typeSelected.length <= 0"
                color="primary"
                >Pesquisa</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
        <template v-if="!numeroClient <= 0">
          <v-card elevation="1" class="d-flex justify-center mt-5" rounded>
            <v-card-text>
              <v-row>
                <h3 class="mt-4">
                  <v-icon left>mdi-account</v-icon>Clientes encontrados:
                  {{ numeroClient }}
                </h3>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey"
                  class="ma-2 white--text"
                  @click="upload"
                  ><v-icon left> mdi-cloud-download </v-icon> Exportar
                  XLSX</v-btn
                >
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import XLSX from "xlsx";
export default {
  data() {
    return {
      loading: false,
      numeroClient: 0,
      clientSearch: {},
      typeSelected: [],
      allTypeClient: [
        {
          nome: "Pessoa física",
          type: "CLIENTE_PF",
        },
        {
          nome: "Pessoa Jurídica",
          type: "CLIENTE_PJ",
        },
      ],
    };
  },

  methods: {
    async searchClient() {
      this.loading = true;
      try {
        const { data } = await axios.get("/clientes-relatorio", {
          params: {
            clientTypes: this.typeSelected,
          },
        });

        this.numeroClient = data.length;
        this.clientSearch = data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    upload() {
      const ws = XLSX.utils.json_to_sheet(this.clientSearch);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Tabela");
      XLSX.writeFile(wb, "clientes.xlsx");
    },
  },
};
</script>
