import { DateTime } from "luxon";
import {
  //getSession,
  setSession,
} from "./index";

/**
 *
 * @param {*} state
 */
function reloadEventos(state) {
  state.eventos = [];

  state.uniqEvento.forEach((item) => {
    state.eventos.push(JSON.parse(item));
  });
}

export default {
  namespaced: true,
  state: {
    /**
     * apontamentos do calendario
     */
    eventos: [],
    uniqEvento: new Set(),
    agendas: [],
  },
  getters: {
    listFilterCreated: (state) => (createdById) => {
      let eventosFiltrados = [];

      for (const evento of state.eventos) {
        if (evento.created_by._id === createdById) {
          let first = DateTime.fromISO(evento.dataHora);
          let second = first.plus({ minutes: 30 });

          let now = DateTime.local();

          let tipoAgenda = evento.cliente.tipoAgenda || "";

          let corAgenda = evento.agenda.cor ?? "blue";
          corAgenda = now > first ? corAgenda + "4D" : corAgenda;
          let corSet =
            evento.status === "REALIZADO" ? "blue-grey lighten-2" : corAgenda;

          eventosFiltrados.push({
            name: `${tipoAgenda} :: ${evento.agenda.nome} :: ${evento.cliente.nome}`,
            start: first.toJSDate(),
            end: second.toJSDate(),
            timed: 1,
            color: corSet,
            dados: evento,
          });
        }
      }

      return eventosFiltrados;
    },
    listEventos: (state) => (agenda) => {
      let eventos = [];

      for (const item of state.eventos) {
        //state.eventos.map((item) => {

        if (agenda && agenda._id !== item.agenda._id) continue;

        let first = DateTime.fromISO(item.dataHora);
        let second = first.plus({ minutes: 20 });

        let now = DateTime.local();

        let tipoAgenda = item.cliente.tipoAgenda || "";

        let corAgenda = item.agenda.cor ?? "blue";
        corAgenda = now > first ? corAgenda + "4D" : corAgenda;
        let corSet =
          item.status === "REALIZADO" ? "blue-grey lighten-2" : corAgenda;
        eventos.push({
          name: `${tipoAgenda} :: ${item.agenda.nome} :: ${item.cliente.nome}`,
          start: first.toJSDate(),
          end: second.toJSDate(),
          timed: 1,
          color: corSet,
          dados: item,
        });
      }

      return eventos;
    },

    listAgendas(state) {
      return state.agendas.filter((elem) => {
        return elem.status;
      });
    },
  },

  mutations: {
    addEvento(state, payload) {
      let entrada = Object.fromEntries(Object.entries(payload).sort());
      state.uniqEvento.add(JSON.stringify(entrada));

      reloadEventos(state);
    },
    setEventos(state, payload) {
      payload.forEach((item) => {
        let entrada = Object.fromEntries(Object.entries(item).sort());
        state.uniqEvento.add(JSON.stringify(entrada));
      });

      reloadEventos(state);
    },

    delEvento(state, payload) {
      for (let elem of state.uniqEvento) {
        let item = JSON.parse(elem);
        if (payload._id === item._id) {
          state.uniqEvento.delete(elem);
        }
      }

      reloadEventos(state);
    },
    updateEvento(state, payload) {
      for (let elem of state.uniqEvento) {
        let item = JSON.parse(elem);
        if (payload._id === item._id) {
          state.uniqEvento.delete(elem);
        }
      }

      this.commit("agendamento/addEvento", payload);
    },

    setAgendas(state, payload) {
      state.agendas = payload;
    },
  },
  actions: {
    async fetchAllEvents({ commit }, { agenda, periodo }) {
      let query = agenda && agenda.nome ? "agenda=" + agenda.nome : "";
      query = periodo
        ? query + `&dia-ini=${periodo[0]}&dia-fim=${periodo[1]}`
        : "";

      const dados = await axios.get(`/agendamento?${query}`);

      commit("setEventos", dados.data);
    },

    async fetchAgendas({ commit }) {
      //let agenda = await getSession("agenda");
      let agenda;

      if (!agenda) {
        const { data } = await axios.get("/agenda");
        await setSession("agenda", data);
        agenda = data;
      }

      commit("setAgendas", agenda);
    },
  },
};
