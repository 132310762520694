<template>
  <v-row>
    <v-col cols="9">
      <div class="text-overline mb-4">Mensagem para WhatsApp</div>

      <v-textarea
        outlined
        label="Agendamento de horário"
        v-model="messages.whatsappMessage"
      ></v-textarea>

      <v-textarea
        outlined
        label="Atendimento Finalizado"
        v-model="messages.whatsappMessageAtendimento"
      >
        <v-tooltip bottom slot="append">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-switch
                v-model="messages.whatsappMessageAtendimentoAtivo"
                color="primary"
                :value="true"
                inset
                hide-details
              ></v-switch>
            </span>
          </template>
          <span>Ativar envio dessa mensagem</span>
        </v-tooltip>
      </v-textarea>
    </v-col>

    <v-col cols="3">
      <p><strong> Variaveis disponiveis</strong></p>
      <p v-html="variaveisDisponiveis"></p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AgendaMensagens",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      msgGroup: {
        whatsappMessageAtendimentoAtivo: "",
        whatsappMessage: "",
        whatsappMessageAtendimento: "",
      },

      variaveisDisponiveis: `{{ data }} <br/>
                        {{ hora }} <br/>
                        {{ cliente.nome }} <br/>
                        {{ cliente.email }} <br/>
                        {{ cliente.telefone }} <br/>
                        {{ cliente.cpfcnpj }} <br/>
                        {{ cliente.produtoEmissao }} <br/>
                        {{ atendimento.ticket }} <br/>
                        `,
    };
  },

  computed: {
      messages: {
          get() {
              return this.value
          },
          set(val) {
           this.$emit("input", val)   
          }
      }
  },

  watch: {
    msgGroup: {
      deep: true,

      handler() {
        //this.$emit("input", this.msgGroup);
      },
    },
  },
};
</script>

<style>
</style>