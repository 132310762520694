<template>
  <v-sheet>
    <v-data-table :headers="headers" :items="dados">
      <!-- actions por registro -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>

      <!-- Topo do table -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Usuários Cadastrados</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <!-- form novo/editar -->
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Cadastrar Novo
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.nome"
                          label="Nome do Usuário"
                          :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.email"
                          label="Email"
                          :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          label="Tipo de Usuário"
                          v-model="editedItem.role"
                          :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                          :items="roles"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-checkbox
                          v-model="editedItem.validBD"
                          label="Desabilitar Banco de dados Valid" 
                          value="disable"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="12">
                        <v-select
                          label="Agenda Exclusiva"
                          v-model="editedItem.agendaSelecionada"
                          :items="agendas"
                          item-text="nome"
                          item-value="_id"
                          outlined
                          clearable
                          hide-details
                          multiple
                          chips
                          class="d-flex"
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12">
                        <select-conta
                          v-model="editedItem.belongsTo"
                          multiple
                        ></select-conta>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.senha"
                          label="Senha"
                          :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Salvar </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- dialog deletar -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card :loading="loadingDelete">
              <v-card-title class="text-h5"
                >Você realmente quer deletar esse item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="red" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import SelectConta from "../components/account/SelectAccount.vue";

export default {
  name: "UsuariosTable",
  components: {
    SelectConta,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      loadingDelete: false,
      headers: [
        { text: "Nome", value: "nome" },
        { text: "Email", value: "email" },
        { text: "Tipo", value: "role" },
        { text: "Ações", value: "actions", sortable: false },
      ],

      dados: [],
      roles: [],

      editedId: null,
      editedItem: {
        nome: "",
      },
      defaultItem: {
        nome: "",
        email: "",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedId === null ? "Cadastrar Novo" : "Editar Valores";
    },
    ...mapState({
      agendas: (state) => state.agendamento.agendas,
    }),
  },

  created() {
    this.initialize();
    this.$store.dispatch("account/fetchAllAccounts");
  },

  methods: {
    async initialize() {
      const result = await axios.get("/user");
      this.dados = result.data;

      const roles = await axios.get("/user/roles");
      this.roles = roles.data;
    },

    async editItem(item) {
      this.dialog = true;
      this.editedId = item._id;
      this.editedItem = Object.assign({}, item);
    },

    async deleteItem(item) {
      this.dialogDelete = true;
      this.editedId = item._id;
    },

    async save() {
      const formValido = this.$refs.form.validate();

      if (!formValido) {
        return false;
      }

      try {
        if (this.editedId === null) {
          await axios.post("/user", this.editedItem);
        } else {
          await axios.put("/user/" + this.editedId, this.editedItem);
        }
      } catch (err) {
        console.log(err);
      }

      this.close();
      this.initialize();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedId = null;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedId = null;
    },

    async deleteItemConfirm() {
      this.loadingDelete = true;
      try {
        await axios.delete("/user/" + this.editedId);
        this.$noty.success("Removido com sucesso");
      } catch (err) {
        this.$noty.error("error: " + err);
      } finally {
        this.loadingDelete = false;
      }

      this.closeDelete();
      this.initialize();
    },
  },
};
</script>

<style></style>
