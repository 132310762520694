<template>
  <div>
    <v-autocomplete
      v-model="valuenew"
      :items="accountList"
      :loading="isLoading"
      :search-input.sync="search"
      outlined
      hide-no-data
      hide-selected
      item-text="name"
      return-object
      hint="Selecione a quem pertence a conta"
      persistent-hint
      :label="label"
      placeholder="Digite nome da conta"
      prepend-inner-icon="mdi-account"
      @input="$emit('input', $event)"
    >
      <template #item="{ item }">
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-bold">
            {{ accountTypeLabel(item.accountType) }}</v-list-item-subtitle
          >
          <v-list-item-subtitle>
            CPF/CNPJ:
            <span>{{ item.cpfcnpj }} </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    value: {
      type: String,
      default: () => "",
    },
    accountType: [String, Array],
    label: {
      type: String,
      default: () => "Conta Principal",
    },
  },
  data() {
    return {
      isLoading: false,
      search: null,
    };
  },
  computed: {
    ...mapGetters("account", ["accountList", "accountTypeLabel"]),
    valuenew: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    async search(val) {
      if (val.length < 3) return;
      this.isLoading = true;
      try {
        const account = val;
        await this.fetchAccounts(account);
      } catch (error) {
        console.error("Erro ao buscar contas:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  methods: {
    ...mapActions("account", ["fetchAccounts"]),
  },
};
</script>
