<template>
  <div>
    <v-autocomplete
      :label="label"
      v-model="accountSelect"
      return-object
      item-text="name"
      item-value="_id"
      placeholder="Começe a digitar para pesquisar"
      :items="listAll"
      v-bind="$attrs"
      :filter="filterAccount"
      :loading="loading"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title
            v-html="item.name"
          ></v-list-item-title>

          <v-list-item-subtitle class="font-weight-bold">
            {{ accountTypeLabel(item.accountType) }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            CPF/CNPJ:
            <span v-html="item.cpfcnpj"> </span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon :to="`/contas/${item._id}`" title="Abrir Cadastro">
            <v-icon color="grey lighten-1">mdi-information</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "SelectConta",

  props: {
    accountType: [String, Array],
    value: [Object, String, Array],
    newForm: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Conta Principal",
    },
  },

  computed: {
    ...mapState({
      loading: state => state.account.loadingAll
    }),
    ...mapGetters("account", ["listAll", "accountTypeLabel"]),
  //   ...mapGetters("account", []),
    accountSelect: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
   
  },

  data() {
    return {};
  },

  methods: {
    filterAccount(item, queryText) {
      const cpf = queryText.replace(/[^0-9]/g, "");
      if (
        cpf.length > 0 &&
        item.cpfcnpj.replace(/[^0-9]/g, "").indexOf(cpf) > -1
      ) {
        return true;
      }

      if (
        item.name &&
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      ) {
        return true;
      }
    },
  },

  mounted() {},
};
</script>

<style>
</style>