import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import axios from "axios";
import { DateTime } from "luxon";

import openReplay from "./plugins/openreplay";
// import Bugsnag from "@bugsnag/js";
// import BugsnagPluginVue from "@bugsnag/plugin-vue";

if (window.location.hostname != "localhost") {
  openReplay.start();

  //import LogRocket from 'logrocket';
  //LogRocket.init('godfst/agenda-senhadigital');

  // Bugsnag.start({
  //   apiKey: "8d6533262161feed4fbecaeca6a4fb24",
  //   plugins: [new BugsnagPluginVue()],
  //   appType: "client",
  //   // onError: function (event) {
  //   //   const user = store.getters.userSession()
  //   //   event.setUser(user._id, user.email, user.nome)
  //   // }
  // });

  // const bugsnagVue = Bugsnag.getPlugin("vue");
  // bugsnagVue.installVueErrorHandler(Vue);
}
import VueCurrencyInput from "vue-currency-input";
Vue.use(VueCurrencyInput, {
  globalOptions: {
    locale: "pt_BR",
    currency: "BRL",
  },
});

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import { facade } from "vue-input-facade";
Vue.directive("mask", facade);

import VueNoty from "vuejs-noty";
import "vuejs-noty/dist/vuejs-noty.css";

Vue.use(VueNoty, {
  timeout: 5000,
  progressBar: true,
});

Vue.filter("format_date", function (date, format) {
  if (!date) return "";
  if (!format) format = "dd/MM/yyyy HH:mm";
  const data = DateTime.fromISO(date);
  return data.isValid ? data.toFormat(format) : "";
});

import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
//import { createProvider } from './vue-apollo'

Vue.use(VueTelInputVuetify, {
  vuetify,
});

window.axios = axios.create({
  baseURL: "/api",
});

// Seta token para cada request
window.axios.interceptors.request.use(
  function (config) {
    // if (!store.getters['auth/isLoggedIn']) {
    //   if (router.path !== '/login') router.push('/login')
    //   return
    // }

    config.headers["x-access-token"] = store.getters["auth/token"];
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// verifica se o token é valido
window.axios.interceptors.response.use(
  (response) => response,
  function (err) {
    const response = err.response;

    return new Promise(function () {
      if (
        response.status === 401 &&
        response.config &&
        !response.config.__isRetryRequest
      ) {
        store.dispatch("auth/logout");

        if (response.config.url != "/login") {
          router.push("/login");
        }
      }

      throw err;
    });
  }
);
function verifyCnpjAR(hostname) {
  const sd = ["localhost", "dev.sdagenda.com.br", "app.sdagenda.com.br"];
  const padrao = ["padrao.sdagenda.com.br"];
  if (sd.includes(hostname)) {
    return "47182342000133";
  } else if (padrao.includes(hostname)) {
    return "35646780000126";
  } else {
    return "";
  }
}
Vue.prototype.$CnpjAR = verifyCnpjAR(window.location.hostname);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  // apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount("#app");
