<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-select
          label="Tipo de Endereço"
          v-model="value.type"
          @input="onInput"
          :items="['Principal', 'Correspondência']"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Endereço"
          v-model="value.address"
          @input="onInput"
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
          label="Número"
          v-model="value.number"
          @input="onInput"
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          label="Bairro"
          v-model="value.district"
          @input="onInput"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="CEP"
          v-model="value.cep"
          @input="onInput"
        ></v-text-field>
      </v-col>

      <v-col cols="8">
        <v-text-field
          label="Cidade"
          v-model="value.city"
          @input="onInput"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-select
          label="Estado"
          v-model="value.state"
          @input="onInput"
          :items="states"
          item-text="name"
          item-value="code"
        ></v-select>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>

import { getStates } from '@brazilian-utils/brazilian-utils';

export default {
  name: "AccountAddress",

  props: ["value"],

  data() {
    return {
      states: getStates()
    };
  },

  methods: {
    onInput() {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style>
</style>