<template>
  <v-sheet>
    <v-data-table :headers="headers" :items="dados">
      <!-- actions por registro -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>

      <template v-slot:item.status="{ item }">
        {{ item.status ? "Ativo" : "Desabilitado" }}
      </template>

      <template v-slot:item._id="{ item }">
        <ButtonCopy :value="item._id" />
      </template>
      <!-- Topo do table -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Agendas Cadastradas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>

          <v-spacer></v-spacer>

          <!-- form novo/editar -->
          <v-dialog v-model="dialog" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Cadastrar Novo
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.nome"
                        label="Nome para a Agenda"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="8">
                      <v-chip-group
                        v-model="editedItem.horariosSelecionados"
                        active-class="primary"
                        column
                        multiple
                        class="my-8"
                      >
                        <v-chip
                          v-for="(
                            hora, index
                          ) in editedItem.horariosSelecionados"
                          :key="index"
                          :value="hora"
                          close
                        >
                          {{ hora }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="novoHorario"
                        label="Novo horário"
                        append-outer-icon="mdi-plus"
                        v-mask="['##:##']"
                        @click:append-outer="addHorario"
                      />
                    </v-col>

                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.quantidade"
                        label="Quantidade Slots por horário"
                        type="number"
                      ></v-text-field>

                      <v-checkbox
                        v-model="editedItem.status"
                        label="Agenda Ativa"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="4">
                      <h3>Selecione a cor para a agenda</h3>
                      <v-color-picker
                        hide-canvas
                        hide-inputs
                        hide-sliders
                        show-swatches
                        @update:color="changeCor"
                        :swatches="cores"
                        swatches-max-height="200"
                        :value="editedItem.cor"
                      ></v-color-picker>
                    </v-col>

                    <v-col cols="12">
                      <v-tabs v-model="fornecedor">
                        <v-tab href="#valid">Valid</v-tab>
                        <v-tab href="#safeweb">SafeWeb</v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="fornecedor">
                        <v-tab-item value="valid">
                          <box-mensagem
                            v-model="editedItem.whatsappMessages['valid']"
                          />
                        </v-tab-item>
                        <v-tab-item value="safeweb">
                          <box-mensagem
                            v-model="editedItem.whatsappMessages['safeweb']"
                          />
                        </v-tab-item>
                      </v-tabs-items>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Salvar </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- dialog deletar -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Você realmente quer deletar esse item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="red" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import marked from "marked/lib/marked.esm";

import BoxMensagem from "../components/AgendaMensagens.vue";
import ButtonCopy from "../components/account/ButtonCopy.vue";

export default {
  name: "AgendasTable",

  components: {
    BoxMensagem,
    ButtonCopy,
  },

  data() {
    return {
      dialog: false,
      dialogDelete: false,

      headers: [
        { text: "Nome", value: "nome" },
        { text: "id", value: "_id" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions", sortable: false },
      ],

      dados: [],
      fornecedor: "valid",
      editedId: null,
      editedItem: {
        nome: "",
        status: true,
        cor: "",
        quantidade: 2,
        rangeDia: [7, 18],
        horariosSelecionados: [],
        whatsappMessages: {
          valid: {
            whatsappMessageAtendimentoAtivo: "",
            whatsappMessage: "",
            whatsappMessageAtendimento: "",
          },

          safeweb: {
            whatsappMessageAtendimentoAtivo: "",
            whatsappMessage: "",
            whatsappMessageAtendimento: "",
          },
        },
      },
      defaultItem: {
        nome: "",
        status: true,
        cor: "",
        quantidade: 2,
        rangeDia: [7, 18],
        horariosSelecionados: [],
        whatsappMessages: {
          valid: {
            whatsappMessageAtendimentoAtivo: "",
            whatsappMessage: "",
            whatsappMessageAtendimento: "",
          },

          safeweb: {
            whatsappMessageAtendimentoAtivo: "",
            whatsappMessage: "",
            whatsappMessageAtendimento: "",
          },
        },
      },

      slotsHorarios: [],

      cores: [
        ["#F44336", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3"],
        ["#03A9F4", "#00BCD4", "#009688", "#4CAF50", "#8BC34A", "#CDDC39"],
        ["#FFEB3B", "#FFC107", "#FF9800", "#FF5722", "#795548", "#607D8B"],
      ],

      novoHorario: "",
    };
  },

  computed: {
    formTitle() {
      return this.editedId === null ? "Cadastrar Novo" : "Editar Valores";
    },

    corAtual() {
      return this.editedItem.cor;
    },

    slotsHorariosSelecionados() {
      let horarios = [];
      let i = this.editedItem.rangeDia[0];
      let fim = this.editedItem.rangeDia[1];

      for (i; i < fim; i++) {
        horarios.push(i + ":00");
        horarios.push(i + ":15");
        horarios.push(i + ":30");
        horarios.push(i + ":45");
      }

      return horarios;
    },

    compiledMarkdown: function () {
      return marked(this.editedItem.whatsappMessage, { breaks: true });
    },
  },

  watch: {
    // slotsHorariosSelecionados(value){
    //   this.editedItem.horariosSelecionados = value
    // }
  },

  created() {
    this.initialize();
    this.generateSlots();
  },

  methods: {
    async initialize() {
      const result = await axios.get("/agenda");
      this.dados = result.data;
    },

    generateSlots() {
      let horarios = [];
      for (let i = 6; i < 20; i++) {
        horarios.push(i + ":00");
        horarios.push(i + ":15");
        horarios.push(i + ":30");
        horarios.push(i + ":45");
      }

      this.slotsHorarios = horarios;
    },

    async editItem(item) {
      this.dialog = true;
      this.editedId = item._id;
      item.horariosSelecionados = item.horariosSelecionados
        ? item.horariosSelecionados
        : [];
      this.editedItem = Object.assign({}, this.defaultItem, item);
    },

    async deleteItem(item) {
      this.dialogDelete = true;
      this.editedId = item._id;
    },

    async save() {
      try {
        if (this.editedId === null) {
          await axios.post("/agenda", this.editedItem);
        } else {
          await axios.put("/agenda/" + this.editedId, this.editedItem);
        }

        this.$noty.success("Adicionado com sucesso");
      } catch (err) {
        this.$noty.error("erro no servidor");
      }

      this.close();
      this.initialize();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedId = null;
        //this.editedItem.horariosSelecionados = this.slotsHorariosSelecionados;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedId = null;
    },

    async deleteItemConfirm() {
      try {
        await axios.delete("/agenda/" + this.editedId);
        this.$noty.success("Removido com sucesso");
      } catch (err) {
        this.$noty.error("erro no servidor");
      }

      this.closeDelete();
      this.initialize();
    },

    changeCor(val) {
      this.editedItem.cor = val.hex;
    },

    addHorario() {
      const novoHorario = this.novoHorario;

      if (!this.editedItem.horariosSelecionados.includes(novoHorario)) {
        this.editedItem.horariosSelecionados.push(novoHorario);
        this.editedItem.horariosSelecionados.sort();
      }

      this.novoHorario = "";
    },
  },
};
</script>

<style>
.whatsapp-preview {
  /* background-image: url("https://web.whatsapp.com/img/bg-chat-tile-light_04fcacde539c58cca6745483d4858c52.png");
    */
  opacity: 0.6;
}
</style>
