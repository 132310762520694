<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="6">
        <template>
          <v-card ref="form" :loading="loading">
            <v-card-text>
              <v-select
                label="Pesquise por conta"
                :items="allAccounts"
                item-text="label"
                :loading="loadingSelect"
                item-value="type"
                multiple
                v-model="accountSelected"
              >
              </v-select>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="mt-4"
                :loading="loading"
                @click="searchAccount"
                :disabled="accountSelected.length <= 0"
                color="primary"
                >Pesquisa</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
        <template v-if="!numeroContas <= 0">
          <v-card
            :loading="loadingUpload"
            elevation="1"
            class="d-flex justify-center mt-5"
            rounded
          >
            <v-card-text>
              <v-row>
                <h3 class="mt-4">
                  <v-icon left>mdi-account</v-icon>Clientes encontrados:
                  {{ numeroContas }}
                </h3>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey"
                  class="ma-2 white--text"
                  @click="upload"
                  ><v-icon left> mdi-cloud-download </v-icon> Exportar
                  XLSX</v-btn
                >
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import XLSX from "xlsx";
export default {
  data() {
    return {
      loading: false,
      loadingSelect: false,
      loadingUpload: false,
      numeroContas: 0,
      accountSearch: {},
      accountSelected: [],
      allAccounts: [],
    };
  },
  mounted() {
    this.loadingSelect = true;
    try {
      this.fetchAllAccounts();
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingSelect = false;
    }
    this.allAccounts = this.accountTypes;
  },
  computed: {
    ...mapGetters("account", ["accountTypes"]),
  },
  methods: {
    ...mapActions("account", ["fetchAllAccounts"]),
    async searchAccount() {
      this.loading = true;
      try {
        const { data } = await axios.get("/accounts-relatorio", {
          params: {
            accountTypes: this.accountSelected,
          },
        });

        this.numeroContas = data.length;
        this.accountSearch = data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async upload() {
      const ws = XLSX.utils.json_to_sheet(this.accountSearch);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Tabela");
      XLSX.writeFile(wb, "contas.xlsx");
    },
  },
};
</script>
