<template>
  <div class="mt-6">
    <v-switch
      v-model="$vuetify.theme.dark"
      inset
      :append-icon="
        $vuetify.theme.dark ? 'mdi-weather-night' : 'mdi-weather-sunny'
      "
      :label="$vuetify.theme.dark ? 'Dark Mode' : 'Ligth Mode'"
      persistent-hint
      >Dark</v-switch
    >
  </div>
</template>

<script>
export default {
  mounted() {
    const saveDarkMode = localStorage.getItem("darkMode");
    if (saveDarkMode !== null) {
      this.$vuetify.theme.dark = saveDarkMode === "true";
    }
    this.$watch("$vuetify.theme.dark", (newVal) => {
      localStorage.setItem("darkMode", newVal);
    });
  },
};
</script>
