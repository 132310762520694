<template>
  <div>
    <v-container>
      <v-tabs v-model="tab">
        <v-tab href="#dados">Dados da Conta</v-tab>
        <v-tab href="#info-extra">Informações de Cadastro</v-tab>
        <v-tab
          href="#proprietario"
          v-if="cadastro.accountType == 'PONTO_ATENDIMENTO'"
          >Informações do Proprietário</v-tab
        >
        <v-tab
          href="#proprietario"
          v-if="cadastro.accountType == 'AC_CERTIFICADORA'"
          >Informações do Proprietário</v-tab
        >
        <v-tab href="#arquivos" v-if="false">Arquivos e Documentos</v-tab>
        <v-tab href="#emissoes" v-if="account_id">Emissões Realizadas</v-tab>
        <v-tab href="#relacionados" v-if="account_id"
          >Contas Relacionadas</v-tab
        >
        <v-tab href="#price">Tabela Preço</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-form ref="form" lazy-validation>
          <v-tab-item value="dados">
            <v-container>
              <v-row class="mt-4">
                <v-col cols="6">
                  <v-select
                    label="Tipo de Conta"
                    v-model="cadastro.accountType"
                    :items="accountTypes"
                    item-text="label"
                    item-value="type"
                    outlined
                    :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col :cols="$route.params.id !== 'new' ? '10' : '12'"
                      ><div v-if="autoCompleteCondition">
                        <v-select
                          outlined
                          :items="cadastro.belongsTo"
                          label="Conta Principal"
                          item-text="name"
                          hint="Selecione a quem pertence a conta"
                          persistent-hint
                          return-object
                          v-model="cadastro.belongsTo"
                          disabled
                        ></v-select>
                      </div>
                      <div v-else>
                        <AutoComplete
                          v-model="cadastro.belongsTo"
                          :account-type="belongsToType"
                        /></div
                    ></v-col>
                    <v-col v-if="$route.params.id !== 'new'" cols="2">
                      <v-btn
                        class="mt-3"
                        icon
                        outlined
                        color="primary"
                        @click="autoCompleteCondition = !autoCompleteCondition"
                      >
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nome*"
                    :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                    v-model="cadastro.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" xs="12">
                  <v-text-field
                    label="CPF/CNPJ"
                    v-model="cadastro.cpfcnpj"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" xs="12">
                  <vue-tel-input-vuetify
                    v-model="tempPhone"
                    label="Telefone*"
                    defaultCountry="BR"
                    :preferredCountries="['BR', 'AR', 'UY', 'PY']"
                    :disabledFetchingCountry="true"
                    placeholder=""
                    @input="onPhoneValidate"
                  ></vue-tel-input-vuetify>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Email"
                    v-model="cadastro.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Responsável"
                    v-model="cadastro.consultor"
                    :items="listUsuarios"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-subheader>Endereço de Cadastro</v-subheader>
                  <v-divider></v-divider>
                </v-col>
                <Address v-model="cadastro.address"></Address>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item value="relacionados">
            <account-relationship :account-id="account_id">
            </account-relationship>
          </v-tab-item>

          <v-tab-item value="emissoes">
            <v-container v-if="account_id">
              <v-data-table :headers="headerAtendimentos" :items="atendimentos">
                <template v-slot:item.dataHora="{ item }">
                  {{ item.dataHora | format_date }}
                </template>
              </v-data-table>
            </v-container>
          </v-tab-item>

          <v-tab-item value="arquivos"> </v-tab-item>

          <v-tab-item value="proprietario">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nome*"
                    :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                    v-model="cadastro.proprietario.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" xs="12">
                  <v-text-field
                    label="CPF/CNPJ"
                    v-model="cadastro.proprietario.cpfcnpj"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" xs="12">
                  <vue-tel-input-vuetify
                    v-model="cadastro.proprietario.phone"
                    label="Telefone*"
                    defaultCountry="BR"
                    :preferredCountries="['BR', 'AR', 'UY', 'PY']"
                    :disabledFetchingCountry="true"
                    placeholder=""
                  ></vue-tel-input-vuetify>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Email"
                    v-model="cadastro.proprietario.email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item value="info-extra">
            <v-container>
              <v-row class="mt-4">
                <v-col cols="4">
                  <v-select
                    :items="
                      [
                        'Ativo',
                        'Inativo',
                        'Em Treinamento',
                        'Desistente',
                        'Novo Cadastro',
                      ].sort()
                    "
                    label="Status"
                    v-model="cadastro.status"
                  ></v-select>
                </v-col>

                <v-col cols="4">
                  <input-date
                    v-model="cadastro.dataCadastro"
                    label="Data de Cadastro"
                  ></input-date>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Código do Site Parceiro"
                    v-model="cadastro.codSiteParceiro"
                  />
                </v-col>
              </v-row>

              <div v-if="cadastro.accountType === 'AGENTE_REGISTRO'">
                <v-row class="mt-4">
                  <v-col cols="3">
                    <v-select
                      :items="[1, 2, 3]"
                      label="Perfil de Emissão"
                      v-model="cadastro.perfilEmissao"
                    ></v-select>
                  </v-col>

                  <v-col cols="3">
                    <v-select
                      :items="[1, 2]"
                      label="Modalidade de Treinamento"
                      v-model="cadastro.treinamento.modalidade"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <input-date
                      v-model="cadastro.treinamento.dataTreinamentoInicio"
                      label="Data de Inicio do Treinamento"
                    ></input-date>
                  </v-col>
                  <v-col cols="3">
                    <input-date
                      v-model="cadastro.treinamento.dataTreinamentoConclusao"
                      label="Data Conclusão do Treinamento"
                    ></input-date>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="cadastro.treinamento.entidade"
                      label="Entidade do treinamento"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="cadastro.treinamento.grafoscopia"
                      label="Entidade emissora da grafoscopia"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <input-date
                      v-model="cadastro.dataNascimento"
                      label="Data de Nascimento"
                    ></input-date>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="RG"
                      v-model="cadastro.rg"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Orgão Emissor"
                      v-model="cadastro.rgEmissor"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Carteira de Trabalho"
                      v-model="cadastro.carteiraTrabalho"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Nome da Mãe"
                      v-model="cadastro.nomeMae"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Nome do Pai"
                      v-model="cadastro.nomePai"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      label="Escolaridade"
                      v-model="cadastro.escolaridade"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <input-date
                      v-model="cadastro.dataEnsinoConclusao"
                      label="Data de Conclusão"
                    ></input-date>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Instituição de Ensino"
                      v-model="cadastro.instituicaoEnsino"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <!-- fim revenda -->

              <div
                v-if="
                  ['REVENDA', 'PARCEIRO', 'PONTO_ATENDIMENTO'].includes(
                    cadastro.accountType
                  )
                "
              >
                <v-row>
                  <v-col cols="3">
                    <input-date
                      v-model="cadastro.dataBoasVindas"
                      label="Data de Boas Vindas"
                    ></input-date>
                  </v-col>
                  <v-col cols="3">
                    <input-date
                      v-model="cadastro.dataTreinamentoInicio"
                      label="Data de Inicio do Treinamento"
                    ></input-date>
                  </v-col>
                  <v-col cols="3">
                    <input-date
                      v-model="cadastro.dataTreinamentoConclusao"
                      label="Data Conclusão do Treinamento"
                    ></input-date>
                  </v-col>
                  <v-col cols="3">
                    <input-date
                      v-model="cadastro.dataHabilitacao"
                      label="Data de Habilitação"
                    ></input-date>
                  </v-col>

                  <v-col cols="4">
                    <v-select
                      :items="['Comissão', 'Compra', 'Tec-D', 'Split']"
                      label="Remuneração"
                      v-model="cadastro.remuneracao"
                    ></v-select>
                  </v-col>

                  <v-col cols="4" class="d-flex">
                    <v-select
                      :items="itensSimNao"
                      item-text="label"
                      item-value="value"
                      label="Comissão"
                      v-model="cadastro.temComissao"
                      class="mr-6"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Valor %"
                      v-model="cadastro.valorComissao"
                      type="number"
                      :disabled="!cadastro.temComissao"
                    />
                  </v-col>

                  <v-col cols="6">
                    <v-textarea
                      label="Código do cupom"
                      v-model="cadastro.codCupom"
                      outlined
                      hint="Um por linha"
                      persistent-hint
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      label="Observações"
                      v-model="cadastro.observacoes"
                      outlined
                    />
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-tab-item>

          <v-tab-item value="price">
            <v-row>
              <v-col cols="8">
                <TabelaPreco
                  v-if="this.account_id == null"
                  :zeroedDataTable="productsAccount"
                  :priceTabela="cadastro.priceTabela"
                  @price-tabela="priceTabela"
                />
                <TabelaPreco
                  v-else
                  :priceTableUpdate="cadastro.priceTabela"
                  :loadingCard="loadingCard"
                  :actionTable="refreshProducts"
                  :priceTabela="cadastro.priceTabela"
                  @price-tabela="priceTabela"
                />
              </v-col>
              <v-col
                cols="4"
                v-if="account_id !== null"
                class="text-end mt-5 text-caption blue-grey--text text--lighten-2"
              >
                <v-timeline dense>
                  <v-timeline-item color="success" small>
                    <v-row>
                      <v-col cols="6">
                        <strong v-if="created && created.created_by"
                          >Criado: {{ created.created_by.nome }}</strong
                        >
                        <div class="text-caption" v-if="created">
                          {{ created.created_at | format_date }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                  <div v-for="(item, index) in update_log" :key="index">
                    <v-timeline-item color="info" small>
                      <v-row>
                        <v-col cols="6">
                          <strong v-if="item.updated_by"
                            >Atualizado: {{ item.updated_by.nome }}</strong
                          >
                          <div class="text-caption">
                            {{ item.updated_at | format_date }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </div>
                </v-timeline>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-container>
            <v-row class="mt-8" cols="12" justify="space-between">
              <v-btn color="secundary" to="/contas">Voltar</v-btn>
              <v-btn color="primary" @click="salvar">Salvar</v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import AccountRelationship from "./account/Relationship.vue";
import TabelaPreco from "./account/TabelaPreco.vue";
// import SelectConta from "./account/SelectAccount.vue";
import AutoComplete from "./account/AutoComplete.vue";
import { mapState, mapGetters } from "vuex";
import Address from "./account/Address.vue";
import InputDate from "./InputDate.vue";

export default {
  name: "AccountForm",
  components: {
    // SelectConta,
    AccountRelationship,
    Address,
    InputDate,
    TabelaPreco,
    AutoComplete,
  },

  data: () => ({
    contaspesquisada: {},
    cadastro: {
      address: {
        type: "Principal",
      },
      proprietario: {
        name: "",
      },
      priceTabela: [],
    },
    tempPhone: "",
    account_id: null,
    tab: null,
    comissao: false,
    autoCompleteCondition: false,
    loadingCard: false,
    atendimentos: [],
    update_log: [],
    created: {},
    itensSimNao: [
      {
        label: "Sim",
        value: true,
      },
      {
        label: "Não",
        value: false,
      },
    ],

    headerAtendimentos: [
      {
        text: "Nome no Cliente",
        value: "cliente.nome",
      },
      {
        text: "Data Emissão",
        value: "dataHora",
      },
      {
        text: "Produto",
        value: "cliente.produtoEmissao",
      },
      {
        text: "Ticket",
        value: "atendimento.ticket",
      },
      {
        text: "Valor Pago",
        value: "atendimento.valorPago",
      },
    ],

    headers: [
      { text: "Nome", value: "name" },
      {
        text: "Tipo de Conta",
        sortable: false,
        value: "accountType",
      },
      { text: "Cpf/Cnpj", value: "cpfcnpj", sortable: false },
    ],
  }),

  computed: {
    ...mapState("productsAccount", ["productsAccount"]),
    ...mapState({
      listUsuarios: (state) => state.usuarios.map((v) => v.nome),
    }),
    ...mapGetters({ accountTypes: "account/accountTypes" }),
    /* filtro de conta */
    belongsToType() {
      let type = "";
      switch (this.cadastro.accountType) {
        case "CONTADOR":
          type = "REVENDA";
          break;

        case "REVENDA":
          type = "";
          break;

        case "PONTO_ATENDIMENTO":
          type = "AUTORIDADADE_REGISTRO";
          break;

        case "AUTORIDADADE_REGISTRO":
          type = "PONTO_ATENDIMENTO";
          break;

        case "AC_CERTIFICADORA":
          type = "AC_CERTIFICADORA";
          break;

        default:
          type = "";
      }

      return type;
    },

    infoExtra() {
      return this.cadastro.accountType
        ? `info-extra-${this.cadastro.accountType.toLowerCase()}`
        : "";
    },
  },

  watch: {
    "cadastro.accountType": function (val) {
      if (val == "AGENTE_REGISTRO") {
        this.cadastro.treinamento = {
          modalidade: "",
        };
      }
    },
  },

  methods: {
    onPhoneValidate(formattedNumber, { number, valid }) {
      this.cadastro.phoneNumber = number.international;
      this.tempPhone = number.national;

      if (!valid) {
        //
      }
    },
    async salvar() {
      const formValido = this.$refs.form.validate();

      if (!formValido) {
        return false;
      }

      try {
        if (this.account_id === null) {
          await axios.post("/account", this.cadastro);
        } else {
          await axios.put("/account/" + this.account_id, this.cadastro);
        }
        this.$router.push("/contas");
        this.$noty.success("Adicionado com sucesso");
      } catch (err) {
        this.$noty.error("Erro no servidor");
      }
    },

    priceTabela(newPriceTabela) {
      this.cadastro.priceTabela = newPriceTabela;
    },

    async refreshProducts() {
      this.loadingCard = true;
      try {
        this.cadastro.priceTabela = this.productsAccount;
        await axios.put("/account/" + this.account_id, this.cadastro);
        this.getAccount(this.account_id);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingCard = false;
      }
    },

    async getAccount(id) {
      const { data } = await axios.get("/account/" + id);
      this.cadastro = Object.assign({}, this.cadastro, data);
      this.tempPhone = data.phoneNumber;
      this.atendimentos = data.atendimentos;
      this.update_log = data.update_log;
      this.created = data.created;

      if (this.cadastro.atendimentos) delete this.cadastro.atendimentos;
    },
  },

  mounted() {
    this.message = "";

    if (this.$route.params.id !== "new") {
      this.getAccount(this.$route.params.id);
      this.account_id = this.$route.params.id;
      this.autoCompleteCondition = true;
    }

    this.$store.dispatch("carregarUsuarios");
    this.$store.dispatch("clientes/fetchAllClientes");
    this.$store.dispatch("account/fetchAllAccounts");
  },
};
</script>
