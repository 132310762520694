export default {
  namespaced: true,
  state: {
    safeweb: null,
    products: [],
    preCheckCpfCnpj: null,
    psbio: null,
  },
  getters: {
    allProducts: (state) => {
      const uniqueProductsMap = state.products?.reduce((acc, product) => {
        if (!acc[product.idProduto]) {
          acc[product.idProduto] = {
            ...product,
            nomeCompletoProduto: `${product.Nome} ${product.ProdutoValidade} ${product.Descricao} - ${product.idProduto}`,
          };
        }
        return acc;
      }, {});
      const uniqueProductsArray = Object.values(uniqueProductsMap);

      return uniqueProductsArray;
    },
  },
  mutations: {
    SET_PROTOCOL_SAFEWEB: (state, payload) => (state.safeweb = payload),
    SET_CANCEL_PROTOCOL_SAFEWEB: (state, payload) => (state.safeweb = payload),
    SET_PRODUCT: (state, payload) => (state.products = payload),
    SET_PRE_CHECK_CPF_CNPJ: (state, payload) =>
      (state.preCheckCpfCnpj = payload),
    SET_PSBIO: (state, payload) =>
      (state.psbio = payload),
  },
  //obs ids tipo emissao: 1 = Emissão presencial / 2 = Renovação on-line / 3 = Emissão videoconferência
  actions: {
    async fetchProcol({ commit }, { dados, idTipoEmissao, idAgenda }) {
      const data = await axios.post(
        `/fetchProtocolSafeweb/${idTipoEmissao}/${idAgenda}`,
        dados
      );
      commit("SET_PROTOCOL_SAFEWEB", data);
      return data;
    },
    async cancelProcol({ commit }, { dados }) {
      const data = await axios.post(`/cancelProtocolSafeweb/`, dados);
      commit("SET_CANCEL_PROTOCOL_SAFEWEB", data);
      return data;
    },
    async fetchProductsSafeweb({ commit }, { idTipoEmissao, CnpjAR }) {
      const { data } = await axios.get(
        `/fetchProductSafeweb/${idTipoEmissao}/${CnpjAR}`
      );
      commit("SET_PRODUCT", data);
    },
    async fetchPreCheckCpfCnpj({ commit }, payload) {
      const { data } = await axios.post("/preCheckCpfCnpj", payload);
      commit("SET_PRE_CHECK_CPF_CNPJ", data);
      return data;
    },
    async fetchPSbio({ commit }, payload) {
      const { data } = await axios.post("/PSbio", payload);
      commit("SET_PSBIO", data);
      return data;
    },
  },
};
