<template>
  <v-sheet>
    <v-data-table
      :headers="headers"
      :items="dados"
      :loading="loadingDados"
      :items-per-page="100"
      :footer-props="{
        'disable-items-per-page': true,
        'items-per-page-options': [100],
      }"
      :options.sync="tableOptions"
      :server-items-length="totalItens"
      @update:page="loadData"
    >
      <!-- actions por registro -->
      <template v-slot:item.actions="{ item }">
        <v-btn :to="`/contas/${item._id}`" icon small class="mr-2">
          <v-icon small class="mr-2"> mdi-pencil </v-icon>
        </v-btn>
        <v-icon small @click="deleteItem(item)" v-if="isAdmin">
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:item.cpfcnpj="{ item }">
        <ButtonCopy :value="item.cpfcnpj" />
      </template>
      <!-- Topo do table -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Contas Cadastrados</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Filtrar"
            single-line
            hide-details
            @input="searchText"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn color="primary" dark class="mb-2" to="/contas/new">
            Cadastrar Novo
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.accountType="{ item }">
        {{ accountTypeLabel(item.accountType) }}
      </template>

      <template v-slot:header.accountType="{ header }">
        <div class="d-flex justify-space-between align-baseline">
          <span>{{ header.text }}</span>

          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="filterColor">mdi-filter-variant</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item-group
                v-model="accountTypeFilter"
                color="primary"
                @change="loadData"
              >
                <v-list-item value="ALL" link>
                  <v-list-item-title>Exibir Todos</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-for="(item, index) in accountTypes"
                  :key="index"
                  :value="item.type"
                  link
                >
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </template>

      <template v-slot:header.status="{ header }">
        <div class="d-flex justify-space-between align-baseline">
          <span>{{ header.text }}</span>

          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small>mdi-filter-variant</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item-group
                v-model="statusFilter"
                color="primary"
                @change="loadData"
              >
                <v-list-item value="ALL" link>
                  <v-list-item-title>Exibir Todos</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-for="(item, index) in statusType"
                  :key="index"
                  :value="item"
                  link
                >
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Você realmente quer deletar essa conta?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >Cancelar</v-btn
          >
          <v-btn color="red" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ButtonCopy from "../components/account/ButtonCopy.vue";
import _debounce from "lodash/debounce";
export default {
  name: "Account",
  components: { ButtonCopy },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      search: "",
      loadingDados: false,
      accountTypeFilter: "",
      statusFilter: "",
      tableOptions: {},
      totalItens: 0,

      headers: [
        { text: "Nome", value: "name", sortable: false },
        {
          text: "Tipo de Conta",
          sortable: false,
          value: "accountType",
        },
        { text: "Status", value: "status", sortable: false },
        { text: "Cpf/Cnpj", value: "cpfcnpj", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],

      dados: [],

      editedId: null,
      editedItem: {
        name: "",
      },
      defaultItem: {
        name: "",
        email: "",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedId === null ? "Cadastrar Novo" : "Editar Valores";
    },

    ...mapState({
      agendas: (state) => state.agendamento.agendas,
      statusType: (state) => state.account.statusType,
    }),
    ...mapGetters({ accountTypes: "account/accountTypes" }),
    ...mapGetters("account", ["listAll"]),
    ...mapGetters({ hasRole: "auth/hasRole" }),

    filterColor() {
      return this.accountTypeFilter != "" ? "blue" : "black";
    },

    isAdmin() {
      return this.hasRole(["ADMIN"]);
    },
  },

  created() {
    this.initialize();

    this.searchText = _debounce(() => {
      this.loadData();
    }, 500);
  },

  beforeUnmount() {
    this.searchText.cancel();
  },

  watch: {
    tableOptions: {
      async handler() {
        await this.loadData();
      },
      deep: true,
    },

    search(...args) {
      this.searchText(...args);
    },
  },

  methods: {
    async initialize() {
      // await this.loadData();
    },

    async loadData() {
      this.loadingDados = true;
      //await this.$store.dispatch("account/fetchAllAccounts");
      const filtro = {
        page: this.tableOptions.page,
        query: this.search,
        tipo: this.accountTypeFilter,
        status: this.statusFilter,
      };
      const { data } = await axios.get(`/account`, { params: filtro });
      this.$store.commit("account/setContas", data.data);
      this.dados = this.listAll;
      this.loadingDados = false;
      this.totalItens = data.itemsCount;
    },

    // searchText: _debounce((e) => {
    //   console.log(this, e)
    // }, 2000),

    async editItem(item) {
      this.dialog = true;
      this.editedId = item._id;
      this.editedItem = Object.assign({}, item);
    },

    async deleteItem(item) {
      this.dialogDelete = true;
      this.editedId = item._id;
    },

    async save() {
      const formValido = this.$refs.form.validate();

      if (!formValido) {
        return false;
      }

      try {
        if (this.editedId === null) {
          await axios.post("/account", this.editedItem);
        } else {
          await axios.put("/account/" + this.editedId, this.editedItem);
        }
      } catch (err) {
        console.log(err);
      }

      this.close();
      this.initialize();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedId = null;
    },

    async deleteItemConfirm() {
      try {
        await axios.delete("/account/" + this.editedId);
        this.$noty.success("Removido com sucesso");
      } catch (err) {
        this.$noty.error("erro no servidor", "error");
      }

      this.closeDelete();
      this.initialize();
    },

    accountTypeLabel(accountType) {
      return this.$store.state.account.accountTypeLabel[accountType];
    },
  },
};
</script>

<style></style>
