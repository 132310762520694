<template>
  <div>
    <v-list>
      <v-list-item>
        <v-img :src="require('../assets/SDAgenda.png')"></v-img>
      </v-list-item>

      <v-list-group>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ userSession.nome }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                userSession.email
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item link dense @click="logout">
          <v-list-item-content class="pl-4">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item-group color="primary">
        <v-list-item to="/" link>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Agendamento </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/agendas" link>
          <v-list-item-icon>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Agendas </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/relatorios" link>
          <v-list-item-icon>
            <v-icon>mdi-chart-bar</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Relatórios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/usuarios" link>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Usuários</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/produtos" link>
          <v-list-item-icon>
            <v-icon>mdi-file-certificate-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Produtos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/clientes" link>
          <v-list-item-icon>
            <v-icon>mdi-file-account-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/contas" link>
          <v-list-item-icon>
            <v-icon>mdi-badge-account-horizontal-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Contas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";


export default {
  components:{

  },
  computed: {
    ...mapGetters({ userSession: "auth/usuarioLogado" }),
  },

  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style></style>
