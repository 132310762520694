<template>
  <div class="text-center">
    <v-menu v-model="menu" :close-on-content-click="false" offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="indigo" dark outlined v-bind="attrs" v-on="on"
          ><v-icon>mdi-filter-outline</v-icon> Filtro
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          Pesquisa
          <v-spacer></v-spacer>
          <v-btn icon @click="menu = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  data: () => ({
    menu: false,
  }),
};
</script>
