<template>
  <v-app v-if="logado" dark flat>
    <div v-if="!allLoaded">
      <v-container style="height: 400px">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Carregando suas configurações de Usuário
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <v-navigation-drawer v-model="drawer" app>
        <!-- menu -->
        <menu-lateral></menu-lateral>
      </v-navigation-drawer>

      <v-app-bar app :color="isDevEnv ? 'colorDev' : 'primary'" dark>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <div class="d-flex align-center font-weight-medium text-h6">
          {{ isDevEnv ? " :: Ambiente de Teste ::" : "" }} Agendamento - SD
          Agenda
        </div>

        <v-spacer></v-spacer>
        <DarkMode />
      </v-app-bar>

      <v-main>
        <v-container fluid class="pa-4">
          <router-view />
        </v-container>
      </v-main>
    </div>
  </v-app>

  <v-app v-else>
    <router-view />
  </v-app>
</template>

<script>
import MenuLateral from "@/components/MenuLateral";
import DarkMode from './components/DarkMode.vue'
import { mapGetters } from "vuex";

export default {
  name: "App",

  components: {
    MenuLateral,
    DarkMode
  },

  data: () => ({
    drawer: null,
    erro: false,
  }),

  computed: {
    ...mapGetters({
      logado: "auth/isLoggedIn",
    }),
    ...mapGetters(["allLoaded"]),

    isDevEnv() {
      const dev = ["localhost", "dev.sdagenda.com.br", "demo.sdagenda.com.br"];
      return dev.includes(window.location.hostname) ? true : false;
    },
  },
};
</script>
