<template>
  <div>
    <v-text-field
      label="CPF/CNPJ"
      v-model="cnpj"
      :rules="[(v) => !!v || 'Esse campo é obrigatório']"
      v-mask="['###.###.###-##', '##.###.###/####-##']"
    >
      <template #append-outer v-if="requirementCnpj">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              outlined
              color="primary"
              :loading="loadingCnpj"
              @click="searchCnpj"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Pesquise por CNPJ</span>
        </v-tooltip>
      </template>
    </v-text-field>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      loadingCnpj: false,
    };
  },
  computed: {
    cnpj: {
      get() {
        return this.value ?? this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    requirementCnpj() {
      const cleanedValue = this.cnpj.replace(/\D/g, "");
      if (cleanedValue.length < 12) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async searchCnpj() {
      this.loadingCnpj = true;
      const cnpj = this.cnpj.replace(/\D/g, "");
      try {
        const { data } = await axios.get(`/consulta-externa/${cnpj}`);
        if (!data) {
          this.$noty.error("CNPJ não encontrado");
          return;
        }
        this.$emit("cnpj-data", data);
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingCnpj = false;
      }
    },
  },
};
</script>
