<template>
  <v-sheet>
    <v-data-table 
        :headers="headers" 
        :items="dados" 
        :loading="loadingDados"
        :search="search">
      <!-- actions por registro -->
      <template v-slot:item.actions="{ item }">
        <v-btn :to="`/clientes/${item._id}`" icon small class="mr-2">
          <v-icon small class="mr-2"> mdi-pencil </v-icon>
        </v-btn>
        <v-icon small @click="deleteItem(item)" v-if="isAdmin"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>

      <!-- Topo do table -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Clientes Cadastrados</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Filtrar"
            single-line
            hide-details

          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn color="primary" dark class="mb-2" to="/clientes/new">
            Cadastrar Novo
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Você realmente quer deletar o Cliente?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >Cancelar</v-btn
          >
          <v-btn color="red" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Clientes",

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      search: "",
      loadingDados: false,

      headers: [
        { text: "Nome", value: "name" },
        { text: "Email", value: "email" },
        { text: "Cpf/Cnpj", value: "cpfcnpj" },
        { text: "Ações", value: "actions", sortable: false },
      ],

      dados: [],

      editedId: null,
      editedItem: {
        name: "",
      },
      defaultItem: {
        name: "",
        email: "",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedId === null ? "Cadastrar Novo" : "Editar Valores";
    },

    ...mapState({
      agendas: (state) => state.agendamento.agendas,
    }),
    ...mapGetters({"hasRole": "auth/hasRole"}),

    isAdmin(){
      return this.hasRole(['ADMIN'])
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.loadingDados = true;
      const result = await axios.get("/clientes");
      this.dados = result.data;
      this.loadingDados = false;
    },

    async editItem(item) {
      this.dialog = true;
      this.editedId = item._id;
      this.editedItem = Object.assign({}, item);
    },

    async deleteItem(item) {
      this.dialogDelete = true;
      this.editedId = item._id;
    },

    async save() {
      const formValido = this.$refs.form.validate();

      if (!formValido) {
        return false;
      }

      try {
        if (this.editedId === null) {
          await axios.post("/account", this.editedItem);
        } else {
          await axios.put("/account/" + this.editedId, this.editedItem);
        }
      } catch (err) {
        console.log(err);
      }

      this.close();
      this.initialize();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedId = null;
    },

    async deleteItemConfirm() {
      try {
        await axios.delete("/account/" + this.editedId);
        this.$noty.success("Removido com sucesso");
      } catch (err) {
        this.$noty.error("erro no servidor", "error");
      }

      this.closeDelete();
      this.initialize();
    },
  },
};
</script>

<style>
</style>