<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab href="#dados">Dados da Conta</v-tab>
      <v-tab href="#arquivos" v-if="account_id">Arquivos e Documentos</v-tab>
      <v-tab href="#emissoes" v-if="account_id">Emissões Realizadas</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-form ref="form" lazy-validation>
        <v-tab-item value="dados">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nome do Cliente*"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                  v-model="cadastro.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" xs="12">
                <v-text-field
                  label="CPF/CNPJ"
                  v-model="cadastro.cpfcnpj"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" xs="12">
                <vue-tel-input-vuetify
                  v-model="tempPhone"
                  label="Telefone*"
                  defaultCountry="BR"
                  :preferredCountries="['BR', 'AR', 'UY', 'PY']"
                  :disabledFetchingCountry="true"
                  placeholder=""
                  @input="onPhoneValidate"
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Email"
                  v-model="cadastro.email"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Data de Nascimento"
                  v-model="cadastro.dataNascimento"
                  v-mask="['##/##/####']"
                ></v-text-field>
              </v-col>
            

              
            </v-row>

            <h3 class="mt-4">Contador</h3>
            <v-divider class="mb-1"></v-divider>
            <v-row>
              <v-col cols="12">
                
                <v-combobox
                  v-model="contaSelecionado"
                  :search-input.sync="contaNome"
                  :items="listAll.map((i) => i)"
                  item-text="name"
                  item-value="_id"
                  label="Pesquisar o contador"
                  placeholder="Começe a digitar para pesquisar"
                  prepend-icon="mdi-account-tie"
                  return-object
                  hide-no-data
                  :filter="filterCliente"
                  cache-items
                  @change="onChangeAccount"
                  clearable
                  :loading="loadingAccount"
                >
                  <!-- <template v-slot:item="{item, parent}">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="parent.genFilteredText(item.name)"
                      ></v-list-item-title>
                      <v-list-item-subtitle>CPF/CNPJ: 
                        <span v-html="parent.genFilteredText(item.cpfcnpj)" ></span>
                          </v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template> -->
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        >CPF/CNPJ:
                        <span
                          v-html="item.cpfcnpj"
                        ></span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>


                </v-combobox>
              </v-col>

              
              <v-col cols="6">
                <vue-tel-input-vuetify
                  v-model="cadastro.belongsTo.phoneNumber"
                  label="Telefone do Contador"
                  defaultCountry="BR"
                  :preferredCountries="['BR', 'AR', 'UY', 'PY']"
                  :disabledFetchingCountry="true"
                  placeholder=""
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col cols="6">
                <v-text-field 
                  label="Email do Contador"
                  v-model="cadastro.belongsTo.email"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h3 class="mt-4">Endereço</h3>
                <v-divider></v-divider>
              </v-col>
              <Address v-model="cadastro.address"></Address>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item value="arquivos"> </v-tab-item>

        <v-tab-item value="emissoes">
          <v-container v-if="account_id">
            <h3 class="mt-4">Atendimentos Realizados</h3>
            <v-divider class="mb-4"></v-divider>

            <v-data-table :headers="headerAtendimentos" :items="atendimentos">
              <template v-slot:item.dataHora="{ item }">
                {{ item.dataHora | format_date }}
              </template>
            </v-data-table>
          </v-container>
        </v-tab-item>

        <v-container>
          <v-row class="mt-8" cols="12" justify="space-between">
            <v-btn color="secundary" to="/clientes">Voltar</v-btn>
            <v-btn color="primary" @click="salvar">Salvar</v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-tabs-items>
  </v-container>
</template>

<script>
//import SelectConta from "./account/SelectAccount.vue";
import Address from "./account/Address.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ClienteForm",

  components: {
   // SelectConta,
    Address,
  },

  data: () => ({
    cadastro: {
      address: {
        type: "Principal",
      },
      belongsTo: {
        consultor: ""
      }
    },
    contaSelecionado: null,
    contaNome: "",
    accountType: "CLIENTE_PF",
    tempPhone: "",
    account_id: null,
    atendimentos: [],
    tab: null,
    headerAtendimentos: [
      {
        text: "Data",
        value: "dataHora",
      },
      {
        text: "Produto",
        value: "cliente.produtoEmissao",
      },
      {
        text: "Ticket",
        value: "atendimento.ticket",
      },
      {
        text: "Valor Pago",
        value: "atendimento.valorPago",
      },
    ],
  }),

  computed: {
    ...mapState({
      listUsuarios: (state) => state.usuarios.map((v) => v.nome),
      loadingAccount: state => state.account.loadingAll
    }),
    ...mapGetters("account", ["listAll"]),

    getConsultor() {
      return this.cadastro.belongsTo && this.cadastro.belongsTo.consultor
        ? this.cadastro.belongsTo.consultor
        : null;
    },

    consultor: {
      get() {
 
        return this.cadastro.belongsTo && this.cadastro.belongsTo.consultor
          ? this.cadastro.belongsTo.consultor
          : null;
      },
      set(valor) {
        this.cadastro.belongsTo.consultor = valor;
      }
    },
  },
  methods: {
    onPhoneValidate(formattedNumber, { number, valid }) {
      this.cadastro.phoneNumber = number.international;
      this.tempPhone = number.national;

      if (!valid) {
        //
      }
    },
    async salvar() {
      const formValido = this.$refs.form.validate();

      if (!formValido) {
        return false;
      }

      try {
        if (this.account_id === null) {
          await axios.post("/clientes", this.cadastro);
        } else {
          await axios.put("/clientes/" + this.account_id, this.cadastro);
        }
        this.$router.push("/clientes");
        this.$noty.success("Adicionado com sucesso");
      } catch (err) {
        this.$noty.error("Erro no servidor");
      }
    },

    async getAccount(id) {
      const { data } = await axios.get("/clientes/" + id);
      this.cadastro = data;
      this.tempPhone = data.phoneNumber;
      this.atendimentos = data.atendimentos;
      this.contaSelecionado = data.belongsTo;
    },

    onChangeAccount(e) {
      //if (!e) return false;

      if (typeof e === "object" && e !== null) {
        this.cadastro.belongsTo = e
        return
      }

      this.cadastro.belongsTo = {
          name: e,
          phoneNumber: "",
          email: ""

        }
    },

    filterCliente(item, queryText) {

      
      const cpf = queryText.replace(/[^0-9]/g, "")
      if (cpf.length > 0 && item.cpfcnpj.replace(/[^0-9]/g, "").indexOf(cpf) > -1) {
        return true;
      }

      if (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ){
        return true;
      }

    },
  },

  mounted() {
    this.message = "";

    if (this.$route.params.id !== "new") {
      this.getAccount(this.$route.params.id);
      this.account_id = this.$route.params.id;
    }
    

    this.$store.dispatch("carregarUsuarios");
    this.$store.dispatch("clientes/fetchAllClientes");
    this.$store.dispatch("account/fetchAllAccounts");
  },
};
</script>

<style>
</style>