import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import localForage from "localforage";

// limpa cache se não estiver logado
if (!localStorage.getItem("token")) {
  localForage.clear();
}

export async function getSession(key) {
  try {
    let data = await localForage.getItem(key);
    if (!data) return false;

    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function setSession(key, data) {
  try {
    return await localForage.setItem(key, data);
  } catch (err) {
    console.log(err);
  }
}

//import createPlugin from 'logrocket-vuex';
//import LogRocket from 'logrocket';
//const logrocketPlugin = createPlugin(LogRocket);

import openReplay from "../plugins/openreplay";
import trackerVuex from "@openreplay/tracker-vuex";
const vuexPlugin = openReplay.use(trackerVuex());

import agendamento from "./agendamento";
import auth from "./auth";
import account from "./contas";
import clientes from "./clientes";
import safeweb from "./safeweb";
import productsAccount from "./productsAccount";

const store = new Vuex.Store({
  plugins: [
    //  logrocketPlugin
    vuexPlugin,
  ],
  modules: {
    agendamento,
    auth,
    account,
    clientes,
    productsAccount,
    safeweb,
  },
  state: {
    todosProdutos: [],
    usuarios: [],
    loadingSyncMessage: "",
    syncModule: new Map(),
  },
  getters: {
    listUserNames: (state) => {
      return state.usuarios.map((v) => v.nome);
    },

    allLoaded: (state) => {
      for (const value of state.syncModule) {
        if (value == false) return false;
      }

      return true;
    },
  },

  mutations: {
    setUsuarios(state, payload) {
      state.usuarios = payload;
    },

    setAllProdutos(state, payload) {
      state.todosProdutos = payload;
    },

    syncMessage(state, message) {
      state.loadingSyncMessage = message;
    },
    syncModuleAdd(state, name) {
      state.syncModule.set(name, false);
    },
    syncModuleFinished(state, name) {
      state.syncModule.set(name, true);
    },
  },
  actions: {
    async fetchAllProdutos({ commit }) {
      let produtos = await getSession("produtos");

      if (!produtos) {
        commit("syncModuleAdd", "produtos");
        const { data } = await axios.get("/produto");
        await setSession("produtos", data);
        produtos = data;
      }

      commit("setAllProdutos", produtos);
      commit("syncModuleFinished", "produtos");
    },

    async carregarUsuarios({ commit }) {
      let users = await getSession("users");

      if (!users) {
        commit("syncModuleAdd", "user");
        const { data } = await axios.get("/user");

        await setSession("users", data);
        users = data;
      }

      commit("setUsuarios", users);
      commit("syncModuleFinished", "user");
    },
  },
});

export default store;
