import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import Relatorio from "../views/Relatorios.vue";
import Agenda from "../views/Agendas.vue";
import Atendimento from "../views/Atendimento.vue";
import Usuarios from "../views/Usuarios.vue";
import Produtos from "../views/Produtos.vue";
import Login from "../views/Login.vue";
import Erro from "../views/401.vue";
import Clientes from "../views/Clientes.vue";
import ClienteForm from "../components/ClientesForm";
import Contas from "../views/Contas.vue";
import ContasForm from "../components/ContasForm.vue";
import TicketIssued from "../views/TicketIssued.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Agenda",
    component: Home,
    meta: { role: ["*"] },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/erro",
    name: "Erro",
    component: Erro,
  },
  {
    path: "/relatorios",
    name: "Relatorios",
    component: Relatorio,
    meta: { role: ["ATENDIMENTO", "APOIO"] },
  },
  {
    path: "/agendas",
    name: "AgendasCadastradas",
    component: Agenda,
    meta: { role: ["APOIO"] },
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: Usuarios,
    meta: { role: ["APOIO"] },
  },
  {
    path: "/atendimento/:id",
    name: "Atendimento",
    component: Atendimento,
    meta: { role: ["ATENDIMENTO", "APOIO"] },
  },
  {
    path: "/ticket/:id",
    name: "TicketIssued",
    component: TicketIssued,
  },
  {
    path: "/produtos",
    name: "Produtos",
    component: Produtos,
    meta: { role: ["APOIO"] },
  },

  {
    path: "/clientes",
    name: "Clientes",
    component: Clientes,
    meta: { role: ["APOIO"] },
  },
  {
    path: "/clientes/:id",
    component: ClienteForm,
  },
  {
    path: "/contas",
    name: "Contas",
    component: Contas,
    meta: { role: ["APOIO"] },
  },
  {
    path: "/contas/:id",
    component: ContasForm,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresRole = to.matched.some((record) => record.meta.role);

  if (requiresRole) {
    const isLoggedIn = store.getters["auth/isLoggedIn"];
    const isAdmin = store.getters["auth/isAdmin"];
    const hasRequiredRole = store.getters["auth/hasRole"](to.meta.role);

    if (!isLoggedIn) {
      next("/login");
    } else if (isAdmin || hasRequiredRole) {
      next();
    } else {
      router.app.$noty.error("Acesso não permitido a essa página");
      // next('/login');
    }
  } else {
    next(); // Certifique-se de sempre chamar next()!
  }
});

export default router;
