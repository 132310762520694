<template>
  <v-text-field
    v-model="valor"
    :label="label"
    prepend-icon="mdi-calendar"
    type="date"

  ></v-text-field>
</template>

<script>

import { format } from 'date-fns'

export default {
  name: "InputDate",

  props: ["value", "label"],

  data() {
    return {};
  },

  computed: {
    valor: {
      get() {
        return this.value ?? this.value;
      },
      set(value) {
        this.$emit("input", value)
      },
    },

    maxDate(){
      return format(new Date(), "yyyy-MM-dd")
    }
  },
};
</script>

<style>
</style>