<template>
  <v-btn text @click="copy(value)"
    >{{ value }}<v-icon right>mdi-content-copy</v-icon>
  </v-btn>
</template>
<script>
export default {
  props: {
    value: String,
  },
  methods: {
    copy(value) {
      try {
        navigator.clipboard.writeText(value);
        this.$noty.success("Copiado com sucesso: " + value).setTimeout(800);
      } catch (err) {
        this.$noty.error("ops... houve um erro!");
        console.error(err);
      }
    },
  },
};
</script>
