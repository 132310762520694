<template>
  <div>
    <v-text-field
      label="Número protocolo"
      v-model="protocolo"
      hint="Clique em Capturar para gerar protocolo"
      persistent-hint
      :loading="loading"
      :disabled="!protocolo"
    >
      <template #prepend>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :disabled="disableBtn"
              outlined
              color="primary"
              :loading="loading"
              @click="fetchProtocolForClient"
              fab
              small
            >
              <v-icon>mdi-arrow-expand-down</v-icon>
            </v-btn>
          </template>
          <span>Captura um protocolo e atribui ao cliente atual.</span>
        </v-tooltip>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    client: {
      type: Object,
      default: () => {},
    },
    value: String,
    productSelected: {
      type: Object,
      default: () => {},
    },
    tipoAgenda: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      loading: false,
      disableBtn: false,
    };
  },

  computed: {
    tipoAgendaSelected() {
      const tipoAgenda = {
        Videoconferencia: 3,
        Presencial: 1,
        Delivery: 1,
      };
      const idTipoEmissao = tipoAgenda[this.tipoAgenda] || null;
      return idTipoEmissao;
    },
    protocolo: {
      get() {
        return this.value ?? this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    ...mapActions("safeweb", ["fetchProcol"]),
    async fetchProtocolForClient() {
      if (!this.productSelected) {
        return this.$noty.error("Selecione produto");
      }
      this.loading = true;
      this.disableBtn = true;
      const idTipoEmissao = this.tipoAgendaSelected;
      const cpfcnpj = this.formatCaracteresCpfCnpj(this.client.cpfcnpj);
      const endereco = this.client.endereco;
      const telefone = this.formatTelefoneToSafeweb(this.client.telefone);
      const info = {
        CnpjAR: "35646780000126",
        idProduto: this.productSelected.idProduto.toString(),
        CandidataRemocaoACI: true,
        vendor: "Safeweb",
        produto: `${this.productSelected.Nome} ${this.productSelected.ProdutoValidade} ${this.productSelected.Descricao}`,
      };
      const contato = {
        DDD: telefone.ddd,
        Telefone: telefone.numero,
        Email: this.client.email,
      };
      const dadosCPF = {
        ...info,
        Nome: this.client.nome,
        CPF: cpfcnpj,
        DataNascimento: this.formatDataNascimento(this.client.dataNascimento),
        Contato: {
          ...contato,
        },
        Endereco: {
          ...endereco,
        },
      };
      const dadosCNPJ = {
        ...info,
        RazaoSocial: this.client.nome,
        NomeFantasia: "",
        CNPJ: cpfcnpj,
        Titular: {
          Nome: this.client.nomeTitular,
          CPF: this.formatCaracteresCpfCnpj(this.client.cpfTitular),
          DataNascimento: this.formatDataNascimento(
            this.client.dataNascimentoTitular
          ),
          Contato: {
            ...contato,
          },
          Endereco: {
            ...endereco,
          },
        },
        Contato: {
          ...contato,
        },
        Endereco: {
          ...endereco,
        },
      };
      const dados = cpfcnpj.length < 12 ? dadosCPF : dadosCNPJ;
      const idAgenda = this.$route.params.id;
      try {
        const { data } = await this.fetchProcol({
          dados,
          idTipoEmissao,
          idAgenda,
        });
        this.protocolo = data.protocolo;
        this.$emit("data-safeweb", data);
        this.$emit("update-agendamento");
      } catch (error) {
        console.error(error);
        const { data } = error.response;
        this.$noty.error(data.error.Message);
      } finally {
        this.loading = false;
        this.disableBtn = false;
      }
    },
    formatTelefoneToSafeweb(telefone) {
      const telefoneRegex = /\+(\d{2}) (\d{2}) (.+)/;
      const matches = telefone.match(telefoneRegex);

      if (matches) {
        const pais = "+" + matches[1];
        const ddd = matches[2];
        const numero = matches[3].replace(/\D/g, "");
        return { pais, ddd, numero };
      } else {
        throw new Error("Formato de telefone inválido.");
      }
    },
    formatDataNascimento(value) {
      if (!value) {
        return "";
      } else {
        var partes = value.split("/");
        return partes[2] + "-" + partes[1] + "-" + partes[0];
      }
    },
    formatCaracteresCpfCnpj(value) {
      if (!value) {
        return "";
      } else {
        return value.replace(/\D/g, "");
      }
    },
  },
};
</script>
