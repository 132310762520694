import { getSession, setSession } from "./index";

export default {
  namespaced: true,
  state: {
    contas: [],
    searchContas: [],
    contasAll: [],
    accountTypeLabel: {
      AC_CERTIFICADORA: "AC Certificadora",
      CONTADOR: "Contador",
      PARCEIRO: "Parceiro",
      REVENDA: "Revenda",
      AGENTE_REGISTRO: "Agente de Registro",
      PONTO_ATENDIMENTO: "Ponto de Atendimento",
      AUTORIDADADE_REGISTRO: "Autoridade de Registro",
      CLIENTE_PF: "Cliente PF",
      CLIENTE_PJ: "Cliente PJ",
      PACOTE_EMPRESA: "Pacote Empresa",
    },
    loadingAll: false,
    statusType: [
      "Ativo",
      "Inativo",
      "Em Treinamento",
      "Desistente",
      "Novo Cadastro",
    ],
  },
  getters: {
    listAll(state) {
      return state.contas.filter(
        (el) => !["CLIENTE_PF", "CLIENTE_PJ"].includes(el.accountType)
      );
    },

    accountList: (state) => state.searchContas,

    listByType: (state) => (type) => {
      return state.contas
        .filter((el) => el.accountType === type)
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    accountTypes(state) {
      return Object.keys(state.accountTypeLabel)
        .filter((el) => !["CLIENTE_PF", "CLIENTE_PJ"].includes(el))
        .map((key) => {
          return { type: key, label: state.accountTypeLabel[key] };
        })
        .sort((a, b) => (a.type > b.type ? 1 : -1));
    },

    listBelongsTo: (state) => (accountId) => {
      return state.contas.filter((el) => {
        return el.belongsTo?._id == accountId;
      });
    },

    accountTypeLabel: (state) => (type) => {
      return state.accountTypeLabel[type];
    },
  },
  mutations: {
    setContas(state, payload) {
      state.contas = payload.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    setContasAll(state, payload) {
      state.contasAll = payload;
    },

    loading(state, payload) {
      state.loadingAll = payload;
    },
    SET_ACCOUNT_SEARCH(state, payload) {
      state.searchContas = payload;
    },
  },
  actions: {
    async fetchAllAccounts({ commit }) {
      commit("loading", true);
      let contas = await getSession("contas");

      if (!contas) {
        commit("syncModuleAdd", "contas", { root: true });
        const { data } = await axios.get("/allAccounts");
        await setSession("contas", data);
        contas = data;
      }

      commit("setContas", contas);
      commit("loading", false);
      commit("syncModuleFinished", "contas", { root: true });
    },
    async fetchAccounts({ commit }, account) {
      const { data } = await axios.get("searchAccount", {
        params: {
          account,
        },
      });
      commit("SET_ACCOUNT_SEARCH", data);
    },
  },
};
