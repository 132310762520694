<template>
  <div>
    <v-btn color="teal" dark outlined @click="menu = true"
      ><v-icon>mdi-fingerprint</v-icon> PSbio
    </v-btn>
    <v-dialog v-model="menu" width="620">
      <v-card>
        <v-card-title> Consulta CPF no PSbio </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div
            class="font-weight-bold text-center mt-1"
            style="font-size: 0.9em"
          >
            Informe abaixo o CPF do representante legal/pessoa física para
            verificar se pode ser feita uma venda de certificado por
            videoconferência.
          </div>
          <v-divider class="mt-1"></v-divider>
          <v-form ref="form">
            <div>
              <v-text-field
                class="mt-3"
                outlined
                v-mask="['###.###.###-##']"
                :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                dense
                v-model="cpf"
                label="CPF"
              ></v-text-field>
              <div class="d-flex justify-end">
                <v-radio-group row v-model="radioGroup">
                  <v-radio label="PSBio local" value="local"></v-radio>
                  <v-radio label="PSBio global" value="global"></v-radio>
                </v-radio-group>
              </div>
              <v-btn
                color="primary"
                block
                :loading="loadingBtn"
                @click="consultar"
                ><v-icon left>mdi-magnify</v-icon>Consultar</v-btn
              >
            </div>

            <div
              v-if="encontrado"
              class="green--text text-h6 font-weight-bold text-center mt-3"
            >
              <v-icon color="success" left
                >mdi-checkbox-multiple-marked-circle-outline</v-icon
              >CPF possui biometria coletada no PSBio {{ radioSelected }} do ITI
            </div>
            <div
              v-if="naoEncontrado"
              class="red--text text-h6 font-weight-bold text-center mt-3"
            >
              <v-icon color="error" left
                >mdi-close-circle-multiple-outline</v-icon
              >CPF não possui biometria coletada no PSBio {{ radioSelected }} do
              ITI
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    menu: false,
    encontrado: false,
    naoEncontrado: false,
    cpf: "",
    radioGroup: "local",
    loadingBtn: false,
    radioSelected: "",
  }),
  methods: {
    ...mapActions("safeweb", ["fetchPSbio"]),
    async consultar() {
      const formValido = this.$refs.form.validate();

      if (!formValido) {
        return false;
      }

      const dados = {
        cpf: this.cpf,
        type: this.radioGroup,
      };
      this.loadingBtn = true;
      try {
        const { data } = await this.fetchPSbio(dados);
        this.radioSelected = this.radioGroup;
        if (data.encontrado) {
          this.encontrado = true;
          this.naoEncontrado = false;
        } else {
          this.encontrado = false;
          this.naoEncontrado = true;
        }
      } catch (error) {
        console.error(error);
        this.$noty.error(error);
      } finally {
        this.loadingBtn = false;
      }
    },
  },
};
</script>
<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
}
</style>
