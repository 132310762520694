<template>
  <div>
    <v-tabs v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#atendimento-realizado">Atendimentos Realizados</v-tab>
      <v-tab href="#client">Clientes</v-tab>
      <v-tab href="#accounts">Contas</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="atendimento-realizado">
        <AtendimentoRealizado />
      </v-tab-item>

      <v-tab-item value="client">
        <Client />
      </v-tab-item>

      <v-tab-item value="accounts">
        <Account />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import AtendimentoRealizado from "../components/Relatorios/AtendimentoRealizado";
import Client from "../components/Relatorios/Client";
import Account from "../components/Relatorios/Accounts";
export default {
  data() {
    return {
      tab: null,
    };
  },
  components: {
    AtendimentoRealizado,
    Client,
    Account,
  },
};
</script>
