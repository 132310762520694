<template>
<div >
<v-container>
      <v-layout  wrap>
        <v-flex xs12>
          <v-card height="400" color="transparent" flat>
            <div class="text-h2 mt-5">Acesso negado.</div>
            <div class="grey--text lighten-5">
              Você não tem permissão de acesso a essa tela
            </div>
            
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>