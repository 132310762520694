<template>
  <v-sheet>

    <v-data-table :headers="headers" :items="dados">
      <!-- actions por registro -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>

      <!-- Topo do table -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Agendas Cadastradas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <!-- form novo/editar -->
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Cadastrar Novo
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.nome"
                        label="Nome do Produto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.tipo"
                        label="Tipo"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.sku"
                        label="SKU"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Salvar </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- dialog deletar -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Você realmente quer deletar esse item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="red" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>

export default {
  name: "ProdutosTable",


  data() {
    return {
      dialog: false,
      dialogDelete: false,

      headers: [
      { text: "SKU", value: "sku" },
        { text: "Nome", value: "nome" },
        { text: "Ações", value: "actions", sortable: false },
      ],

      dados: [],

      editedId: null,
      editedItem: {
        nome: "",
        tipo: "",
      },
      defaultItem: {
        nome: "",
        tipo: "",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedId === null ? "Cadastrar Novo" : "Editar Valores";
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const result = await axios.get("/produto");
      this.dados = result.data;
    },

    async editItem(item) {
      this.dialog = true;
      this.editedId = item._id;
      this.editedItem = Object.assign({}, item);
    },

    async deleteItem(item) {
      this.dialogDelete = true;
      this.editedId = item._id;
    },

    async save() {
      try {
        if (this.editedId === null) {
          await axios.post("/produto", this.editedItem);
        } else {
          await axios.put("/produto/" + this.editedId, this.editedItem);
        }

        this.$noty.success("Adicionado com sucesso");
      } catch (err) {
         this.$noty.error("Erro no servidor");
      }

      this.close();
      this.initialize();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedId = null;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedId = null;
    },

    async deleteItemConfirm() {
      try {
        await axios.delete("/produto/" + this.editedId);
        this.$noty.success("Removido com sucesso");
      } catch (err) {
        this.$noty.error("Erro no servidor");
      }

      this.closeDelete();
      this.initialize();
    },
  },
};
</script>

<style>
</style>