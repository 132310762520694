<template>
  <v-data-table
    :loading="loading"
    :headers="headerRelations"
    :items="listRelations"
  >
    <template v-slot:item.accountType="{ item }">
      {{ accountTypeLabel(item.accountType) }}
    </template>
    <template v-slot:item.name="{ item }">
          <router-link :to="`/clientes/${item._id}`" v-if="item._id"> {{ item.name }} </router-link>
          <span v-else>{{ item.name }}</span>
        </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AccountRelationship",

  props: ["AccountId"],

  data() {
    return {
      listRelations: [],
      loading: false,

      headerRelations: [
        {
          text: "Nome no Cliente",
          value: "name",
        },
        {
          text: "CPF/CNPJ",
          value: "cpfcnpj",
        },
        {
          text: "Tipo de Conta",
          value: "accountType",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("account", ["accountTypeLabel"]),
    // ...mapGetters("account", ["listBelongsTo"]),
  },

  methods: {
    async getListRelations() {
      this.loading = true;
      const { data } = await axios.get(`/account/${this.AccountId}/related`);

      this.listRelations = data;

      this.loading = false;
    },
  },

  mounted() {
    this.getListRelations();
  },
};
</script>

<style>
</style>