<template>
  <v-row>
    <v-card class="mt-6 pa-4" flat>
      <v-card-title> Atendimentos Realizados </v-card-title>

      <v-data-table
        loading-text="Carregando registros... Aguarde"
        no-data-text="Selecione o periodo de pesquisa"
        :loading="loading"
        :headers="headers"
        calculate-widths
        :items-per-page="50"
        :footer-props="footerConfig"
        :items="dados"
        :search="search"
      >
        <template v-slot:item.produto="{ item }">
          <span
            v-if="
              item.cliente.produtoSelecionado &&
              item.cliente.produtoSelecionado.nome
            "
          >
            {{ item.cliente.produtoSelecionado.nome }}
          </span>
          <span v-else>
            {{ item.cliente.produtoEmissao }}
          </span>
        </template>

        <!-- Topo do table -->
        <template v-slot:top>
          <v-toolbar flat class="mb-4">
            <v-menu
              ref="dateSelect"
              v-model="dateShow"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  label="Período de Pesquisa"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  readonly
                ></v-text-field>
              </template>
              <v-card>
                <v-date-picker
                  v-model="dateSelect"
                  no-title
                  range
                  :allowed-dates="datasPermitidas"
                ></v-date-picker>
                
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateShow = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="getDados()"> OK </v-btn>
              </v-card>
            </v-menu>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
              clearable
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-select
            v-model="status"
            :items="['REALIZADO', 'CANCELADO']"
            hide-details
            label="Status"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn
              color="blue-grey"
              dark
              :loading="downloadCsv"
              @click="download()"
              ><v-icon left dark> mdi-cloud-download </v-icon> Exportar
              XLSX</v-btn
            >
          </v-toolbar>
        </template>

        <template v-slot:item.dataHora="{ item }">
          {{ item.dataHora | format_date }}
        </template>

        <template v-slot:item.cliente.nome="{ item }">
          <router-link
            :to="`/clientes/${item.cliente.client_id}`"
            v-if="item.cliente.client_id"
          >
            {{ item.cliente.nome }}
          </router-link>
          <span v-else>{{ item.cliente.nome }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-row>
</template>
<script>
import { DateTime } from "luxon";
//const { Parser } = require('json2csv');
//import { parseISO } from 'date-fns'
import XLSX from "xlsx";
import _ from "lodash-es";

export default {
  name: "AtendimentosTable",

  data() {
    return {
      headers: [
        { text: "Usuário", value: "atendimento.agente" },
        { text: "Cliente", value: "cliente.nome" },
        { text: "CPF/CNPJ", value: "cliente.cpfcnpj" },
        { text: "Tipo Agenda", value: "cliente.tipoAgenda" },
        { text: "Localidade", value: "agenda.nome" },
        { text: "Data Atendimento", value: "dataHora", filterable: false },
        {
          text: "Valor Pago",
          value: "atendimento.valorPago",
          filterable: false,
        },
        { text: "Fornecedor", value: "cliente.fornecedor" },
        { text: "Produto", value: "produto" },
        { text: "Num Ticket Atendimento", value: "atendimento.ticket" },
        { text: "Status", value: "status" },
      ],

      search: "",
      loading: false,
      footerConfig: {
        "items-per-page-options": [10, 50, 100, -1],
      },

      dados: [],

      dateSelect: [],
      dateShow: false,

      downloadCsv: false,

      status: "REALIZADO",
    };
  },

  computed: {
    dateFormatted() {
      return this.dateSelect.join(" ~ ");
    },
  },

  methods: {
    async getDados() {
      this.dateShow = false;
      this.loading = true;

      const result = await axios.get("/atendimento", {
        params: {
          "data-ini": this.dateSelect[0],
          "data-fim": this.dateSelect[1],
          "status": this.status,
          csv: false,
        },
      });

      this.loading = false;
      this.dados = result.data;
    },

    async download() {
      const colunas = [
        "cliente.nome",
        "cliente.email",
        "cliente.telefone",
        "cliente.cpfcnpj",
        "cliente.produtoSelecionado.nome",
        "usuarioAgendamento",
        "usuarioContaPertence",
        "agendamentoOrigem",
        "contadorNome",
        "contadorFone",
        "contaTipo",
        "cliente.fornecedor",
        "cliente.ticket",
        "cliente.sitePedido",
        "cliente.atendente",
        "cliente.observacao",
        "consultorNome",
        "cliente.tipoSolicitacao",
        "agenda.nome",
        "cliente.tipoAgenda",
        "dataHora",
        "atendimento.valorPago",
        "atendimento.tipoPagamento",
        "atendimento.finalidade",
        "atendimento.comoConheceu",
        "atendimento.agente",
        "atendimento.ticket",
        "atendimento.observacoes",
        "atendimento.agente",
        "updated_at",
        "status"
      ];

      const merge = this.dados.map((elem) => {
        const row = {};
        for (const head of colunas) {
          let label, value;
          label = value = head;

          if (_.isPlainObject(head)) {
            if (_.isNil(head.value)) return;
            label = head.label;
            value = head.value;
          }

          row[label] = _.get(elem, value, "");
        }
        return row;
      });

      // const dataFormat = (item) => {
      //   item.dataHora = this.formatData(item.dataHora)
      //   item.updated_at = this.formatData(item.updated_at)
      //   return item
      // }

      const ws = XLSX.utils.json_to_sheet(merge, {
        header: colunas,
        cellDates: true,
      });

      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws);

      XLSX.writeFile(wb, "atendimentos.xlsx");

      // const json2csvParser = new Parser({fields: colunas, transforms: [dataFormat]});

      // const blob = new Blob([json2csvParser.parse(this.dados)], { type: 'text/csv' })
      // const link = document.createElement('a')
      // link.href = URL.createObjectURL(blob)
      // link.setAttribute('download', 'atendimentos.csv');
      // link.click()
      // URL.revokeObjectURL(link.href)
    },

    formatData(date) {
      const data = DateTime.fromISO(date);
      return data.isValid ? data.toFormat("dd/MM HH:mm") : "";
    },

    datasPermitidas(val) {
      const now = DateTime.local();
      return now.startOf("day").toISODate() >= val;
    },
  },

  created() {
    // this.initialize();
  },
};
</script>

<style></style>
