<template>
  <v-dialog v-model="openDialog" persistent max-width="960px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on"> Novo Agendamento </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">Agendamento de Atendimento</span>
      </v-card-title>

      <v-card-text>
        <v-overlay :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-form ref="formCadastro" v-model="formValid" lazy-validation>
          <v-container>
            <h3>Dados do cliente</h3>
            <v-divider class="mb-4"></v-divider>
            <v-row>
              <v-col cols="6">
                <v-select
                  :items="['Novo Certificado', 'Renovação']"
                  label="Tipo de Solicitação"
                  outlined
                  v-model="cadastro.tipoSolicitacao"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-select
                  :items="['Presencial', 'Videoconferencia', 'Delivery']"
                  label="Tipo de Agenda"
                  outlined
                  v-model="cadastro.tipoAgenda"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-combobox
                  v-model="clienteSelecionado"
                  :search-input.sync="cadastro.nome"
                  :items="clientesEncontrados"
                  :loading="loadingClientes"
                  item-text="name"
                  item-value="_id"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                  label="Pesquise por CPF/CNPJ do cliente"
                  placeholder="Começe a digitar para pesquisar"
                  prepend-icon="mdi-account-search-outline"
                  return-object
                  hide-no-data
                  cache-items
                  :filter="filterCliente"
                  @change="setCliente"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-html="item.name"></v-list-item-title>
                      <v-list-item-subtitle
                        >CPF/CNPJ:
                        <span v-html="item.cpfcnpj"></span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="6" xs="12">
                <v-text-field
                  label="CPF/CNPJ"
                  v-model="cadastro.cpfcnpj"
                  :rules="inputValidation()"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" xs="12">
                <vue-tel-input-vuetify
                  v-model="tempPhone"
                  label="Telefone*"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                  defaultCountry="BR"
                  :preferredCountries="['BR', 'AR', 'UY', 'PY']"
                  :disabledFetchingCountry="true"
                  placeholder=""
                  @input="onPhoneValidate"
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Email"
                  :rules="inputValidation()"
                  v-model="cadastro.email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Data nascimento"
                  v-mask="['##/##/####']"
                  persistent-hint
                  hint="Preencha data DD/MM/AAAA"
                  v-model="cadastro.dataNascimento"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" v-if="requirementCnpj">
                <v-text-field
                  label="Nome do titular do CNPJ"
                  required
                  v-model="cadastro.nomeTitular"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" v-if="requirementCnpj">
                <v-text-field
                  label="CPF titular do CNPJ"
                  v-mask="['###.###.###-##']"
                  required
                  v-model="cadastro.cpfTitular"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" v-if="requirementCnpj">
                <v-text-field
                  label="Data nascimento titular CNPJ"
                  v-mask="['##/##/####']"
                  persistent-hint
                  hint="Preencha data DD/MM/AAAA"
                  v-model="cadastro.dataNascimentoTitular"
                ></v-text-field>
              </v-col>
               <v-col cols="12">
                <UploadDocs
                    ref="refDoc"
                    tipo-doc="Documento"
                    @upload-success="UploadSuccessDoc"
                  />
                <!-- <v-file-input
                  truncate-length="30"
                  label="Documento de identificação"
                  accept="image/*,.pdf"
                  :append-outer-icon="(fileCNH)? 'mdi-upload': ''"
                  hint="Selecione o Arquivo e clique no botão Upload"
                  persistent-hint
                  v-model="fileCNH"
                  @click:append-outer="uploadCNH"
                  @click:clear="cadastro.arquivoCNH = null"
                  :loading="fileLoading"
                  :disabled="fileLoading"
                ></v-file-input> -->
              </v-col>

             <!-- <v-col cols="9">
                <v-file-input
                  truncate-length="30"
                  label="C. Social"
                  accept="image/*,.pdf"
                  :append-outer-icon="(fileSocial)? 'mdi-upload': ''"
                  hint="Selecione o Arquivo e clique no botão Upload"
                  persistent-hint
                  v-model="fileSocial"
                  @click:append-outer="uploadSocial"
                  @click:clear="cadastro.arquivoSocial = null"
                  :loading="fileLoading"
                  :disabled="fileLoading"
                ></v-file-input>
              </v-col> -->
            </v-row>

            <h3 class="mt-4">Parceiro</h3>
            <v-divider class="mb-1"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="contaSelecionado"
                  :search-input.sync="contaNome"
                  :items="listAll.map((i) => i)"
                  item-text="name"
                  item-value="_id"
                  label="Pesquisar o parceiro"
                  placeholder="Começe a digitar para pesquisar"
                  prepend-icon="mdi-account-tie"
                  return-object
                  hide-no-data
                  :filter="filterCliente"
                  cache-items
                  @change="onChangeAccount"
                  clearable
                  :loading="loadingAccount"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-html="item.name"></v-list-item-title>
                      <v-list-item-subtitle
                        >CPF/CNPJ:
                        <span v-html="item.cpfcnpj"></span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="6">
                <vue-tel-input-vuetify
                  v-model="cadastro.belongsTo.phoneNumber"
                  label="Telefone do Contador"
                  defaultCountry="BR"
                  :preferredCountries="['BR', 'AR', 'UY', 'PY']"
                  :disabledFetchingCountry="true"
                  placeholder=""
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Email do Contador"
                  v-model="cadastro.belongsTo.email"
                />
              </v-col>
            </v-row>

            <h3 class="mt-4">Informações do Produto</h3>
            <v-divider class="mb-4"></v-divider>

            <v-row>
              <v-col cols="12" sm="6" xs="12">
                <v-select
                  label="Produto*"
                  v-model="selectedProduto"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                  :items="produtos"
                  item-text="nome"
                  return-object
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" xs="12">
                <v-text-field
                  label="Num Protocolo"
                  v-model="cadastro.ticket"
                  :rules="ticketValidation()"
                  hint="Deixar em branco para usar banco de Ticket interno"
                  v-mask="['###########']"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-select
                  label="Responsável"
                  v-model="consultor"
                  :items="listUsuarios"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  label="Número do Pedido no Site"
                  v-model="cadastro.sitePedido"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  label="Observações"
                  outlined
                  v-model="cadastro.observacoes"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>

            <h3 class="mt-4">Informações de Pagamento</h3>
            <v-divider class="mb-4"></v-divider>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Valor Pago"
                  v-currency="{ currency: 'BRL', locale: 'pt-BR' }"
                  v-model="cadastro.pagamento.valor"
                  ref="valorPago"
                />
              </v-col>
              <v-col cols="12">
                <UploadDocs
                  ref="refComprovante"
                  tipo-doc="Comprovante"
                  @upload-success="UploadSuccessComprovante"
                />
              </v-col>
              <!-- <v-col cols="8">
                <v-file-input
                  truncate-length="30"
                  label="Comprovante"
                  accept="image/*,.pdf"
                  :append-outer-icon="fileComprovante ? 'mdi-upload' : ''"
                  hint="Selecione o Arquivo e clique no botão Upload"
                  persistent-hint
                  v-model="fileComprovante"
                  @click:append-outer="uploadComprovante"
                  @click:clear="cadastro.pagamento.comprovante = null"
                  :loading="fileLoading"
                  :disabled="fileLoading"
                  :rules="fileValidation()"
                ></v-file-input>
              </v-col> -->
            </v-row>

            <h3 class="mt-4">Selecione o Horário*</h3>
            <v-divider class="mb-4"></v-divider>

            <v-row>
              <v-col cols="12">
                <v-select
                  label="Selecione a localidade*"
                  v-model="agendaSelecionada"
                  :rules="[(v) => !!v['_id'] || 'Esse campo é obrigatório']"
                  :items="listAgendas"
                  item-text="nome"
                  return-object
                  @change="listHorariosDisponiveis"
                ></v-select>
              </v-col>

              <v-col md="6" cols="12">
                <v-date-picker
                  v-model="dataHoraISO"
                  full-width
                  elevation="1"
                  @change="listHorariosDisponiveis"
                  :disabled="agendaSelecionada && false"
                  :allowed-dates="datasPermitidas"
                ></v-date-picker>
              </v-col>
              <v-col md="6" cols="12">
                <v-alert v-if="dataErro" dense outlined type="error">
                  É obrigatório selecionar um horário
                </v-alert>

                <v-chip-group v-model="dataHora" active-class="primary" column>
                  <v-chip
                    v-for="(hora, index) in horariosDisponiveis"
                    :key="index"
                    :value="hora.datetime.toISO()"
                    large
                    :class="{ 'purple lighten-3': hora.idle == false }"
                    :title="hora.idle == false ? 'Horario já preenchido' : ''"
                    :disabled="hora.block"
                  >
                    {{ hora.datetime | format_date("HH:mm") }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secundary" text @click="openDialog = false">
          Cancelar
        </v-btn>
        <v-btn color="blue" outlined @click="submitWithMessage">
          Salvar e Enviar Mensagem
        </v-btn>
        <v-btn v-if="editedId" color="blue darken-4" outlined @click="submit">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";
import { mapState, mapGetters } from "vuex";
import _pick from "lodash-es/pick";
import UploadDocs from "./UploadDocs.vue";
//import SelectConta from "./account/SelectAccount.vue";
//import { getValue, setValue } from "vue-currency-input"

export default {
  name: "FormAgendamento",
  components: {
    // SelectConta,
    UploadDocs,
  },

  data: () => ({
    searchCpfCnpj: "",
    clientesEncontrados: [],
    switchMe: false,
    files: [],
    item: [],
    openDialog: false,
    formValid: true,
    editedId: null,
    agendaSelecionada: "",
    contaSelecionado: null,
    dialogSendWhatsApp: false,
    selectedProduto: "",
    cadastro: {
      nome: "",
      email: "",
      telefone: "",
      ticket: "",
      dataNascimento: "",
      dataNascimentoTitular: "",
      cpfcnpj: "",
      atividade: "",
      produtoEmissao: "",
      tipoSolicitacao: null,
      belongsTo: {
        name: "",
      },
      pagamento: {
        valor: null,
        comprovante: null,
        status: null,
      },
    },
    fileComprovante: null,
    fileLoading: false,
    fileCNH: null,
    fileSocial: null,
    // valorTemp: null,

    sendMessage: false,
    dataHora: "",
    horariosDisponiveis: [],
    loading: false,
    contaNome: "",
    dataErro: false,
    tempPhone: "",
    loadingClientes: false,
    clienteSelecionado: null,
    nomeSearch: "",
  }),

  computed: {
    dataHoraISO: {
      get() {
        return DateTime.fromISO(this.dataHora).toISODate();
      },

      set(value) {
        this.dataHora = DateTime.fromISO(value).toISODate();
      },
    },
    agendasNomes() {
      return this.agendas.map((v) => v.nome);
    },

    requirementCnpj() {
      const cpfcnpj = this.cadastro?.cpfcnpj ? this.cadastro.cpfcnpj : "";
      const cleanedValue = cpfcnpj.replace(/\D/g, "");
      if (cleanedValue.length < 12) {
        return false;
      } else {
        return true;
      }
    },

    ...mapState({
      produtos: (state) => state.todosProdutos,
      listUsuarios: (state) => state.usuarios.map((v) => v.nome),
      //agendas: (state) => state.agendamento.agendas,
      eventos: (state) => state.agendamento.eventos,
      loadingAccount: (state) => state.account.loadingAll,
    }),
    ...mapGetters(["listUserNames"]),
    ...mapGetters({ listAgendas: "agendamento/listAgendas" }),
    ...mapGetters({ hasRole: "auth/hasRole" }),
    ...mapGetters({ usuarioLogado: "auth/usuarioLogado" }),
    ...mapGetters({ clientes: "clientes/listClientes" }),
    ...mapGetters("account", ["listAll"]),

    consultor: {
      get() {
        if (this.cadastro.consultor) {
          return this.cadastro.consultor;
        }

        if (this.clienteSelecionado?.belongsTo?.consultor) {
          return this.clienteSelecionado.belongsTo.consultor;
        }
        return this.cadastro.belongsTo && this.cadastro.belongsTo.consultor
          ? this.cadastro.belongsTo.consultor
          : null;
      },
      set(valor) {
        this.cadastro.belongsTo.consultor = valor;
      },
    },
  },

  watch: {
    // Limpa o form ao esconder
    openDialog: function (val) {
      if (val == false) {
        this.agendaSelecionada = "";
        this.editedId = null;
        this.horariosDisponiveis = [];
        this.dataHora = "";
        this.formValid = true;
        this.tempPhone = "";
        this.$refs.formCadastro.resetValidation();
        this.contaNome = "";
        this.contaSelecionado = null;
        this.fileComprovante = null;
        this.cadastro.pagamento.valor = null;
        this.selectedProduto = "";

        for (const item in this.cadastro) {
          this.cadastro[item] = "";
        }

        this.cadastro.belongsTo = { name: "" };
        this.cadastro.pagamento = { comprovante: null };
        this.cadastro.pagamento = { valor: null };
      } else {
        this.dataHora && this.listHorariosDisponiveis(this.dataHora);

        if (this.selectedProduto == null) {
          let prod = this.produtos.find(
            (el) => el.nome == this.cadastro.produtoEmissao
          );
          this.selectedProduto = prod;
        }
      }
    },

    selectedProduto(val) {
      this.cadastro.produtoEmissao = val.nome;
      this.cadastro.produtoSelecionado = val;
    },

    async ["cadastro.nome"](val) {
      if (!val) return;

      if (val.length < 3) return;

      if (this.loadingClientes) return;
      this.cadastro.nome = val;

      this.loadingClientes = true;

      const { data } = await axios.get(`/clientes?cpfcnpj=${val}`);

      this.clientesEncontrados = data;
      this.loadingClientes = false;
    },
  },

  methods: {
    UploadSuccessDoc(response) {
      this.cadastro.arquivoCNH = response.id;
    },
    UploadSuccessComprovante(response) {
      this.cadastro.pagamento.comprovante = response.id;
    },
    //remove itens file input
    remove(item) {
      this.files.splice(item, 1);
    },
    /**
     * Cadastra agendamento
     */
    async submit() {
      this.dataErro = false;
      const formValido = this.$refs.formCadastro.validate();

      if (!formValido) {
        return false;
      }

      if (!DateTime.fromISO(this.dataHora).hour > 0) {
        this.dataErro = true;
        return false;
      }

      let agendamento = {
        agenda: _pick(this.agendaSelecionada, ["_id", "nome", "cor"]),
        dataHora: this.dataHora,
        cliente: this.cadastro,
        status: "NOVO",
        sendMessage: true,
      };

      try {
        this.loading = true;

        if (this.editedId) {
          agendamento.status = "UPDATE";
          agendamento.sendMessage = this.sendMessage;
          await axios.put("/agendamento/" + this.editedId, agendamento);
          this.$store.commit("agendamento/updateEvento", {
            _id: this.editedId,
            ...agendamento,
          });
        } else {
          let retorno = await axios.post("/agendamento", agendamento);
          this.$store.commit("agendamento/addEvento", retorno.data);
        }

        //this.dialogSendWhatsApp = true
        this.loading = false;
        this.openDialog = false;

        this.$noty.success("Agendado com sucesso");
        this.$refs.refDoc.$refs.myDropzone.removeAllFiles();
        this.$refs.refComprovante.$refs.myDropzone.removeAllFiles();
      } catch (err) {
        this.$noty.error("Oops, something went wrong!");
      }
    },

    submitWithMessage() {
      this.sendMessage = true;
      this.submit();
    },

    /**
     * adiciona regras baseado no perfil do usuario
     */
    inputValidation() {
      let rules = [];

      if (this.usuarioLogado.role == "PARCEIRO") {
        rules.push((v) => !!v || "Esse campo é obrigatório");
      }
      if (this.cadastro.tipoAgenda === "Videoconferencia") {
        rules.push((v) => !!v || "Esse campo é obrigatório");
      }

      return rules;
    },

    fileValidation() {
      let rules = [];

      if (this.fileComprovante && !this.cadastro.pagamento.comprovante) {
        rules.push("Faça o upload do arquivo");
      }
      return rules;
    },

    ticketValidation() {
      let ticketRules = [];
      if (this.cadastro.ticket != ticketRules) {
        ticketRules.push((v) => /[0-9]{8}/.test(v) || "Necessário 8 números");
      }
      return ticketRules;
    },

    /**
     *
     */
    datasPermitidas(val) {
      //const now = DateTime.local();
      //return now.startOf("day").toISODate() <= val

      //Somente dia de semana
      const dia = DateTime.fromSQL(val);
      return dia.weekday < 6;
    },

    /**
     * Gera horarios disponiveis para agendamento
     */
    async listHorariosDisponiveis(dia) {
      this.horariosDisponiveis = [];

      let dataSelecionada = DateTime.fromISO(dia, {
        zone: "America/Sao_Paulo",
      });
      let dataHora = DateTime.fromISO(this.dataHora, {
        zone: "America/Sao_Paulo",
      });

      if (!dataSelecionada.isValid && dataHora.isValid) {
        dataSelecionada = dataHora;
      } else if (!dataHora.isValid) {
        return;
      }

      await this.$store.dispatch("agendamento/fetchAllEvents", {
        periodo: [dia, dia],
      });

      const agendaSelecionada = this.agendaSelecionada;
      try {
        let horariosSelecionados = this.eventos
          .filter((elem) => {
            return elem.agenda._id == agendaSelecionada._id;
          })
          .filter((elem) => {
            return DateTime.fromISO(elem.dataHora, {
              zone: "America/Sao_Paulo",
            }).hasSame(dataSelecionada, "day");
          })
          .map((elem) =>
            DateTime.fromISO(elem.dataHora, { zone: "America/Sao_Paulo" })
          );

        let horarios = this.agendaSelecionada.horariosSelecionados
          .map((elem) => {
            let [hora, minuto] = elem.split(":");

            return dataSelecionada.set({ hour: hora, minute: minuto });
          })
          .sort()
          .map((elem) => {
            /*
              Bloqueia horario de sexta 5:30 para agenda Visconde
            */
            const agendaVisconde = "5ff28ce9ac57f5481f96342c";
            if (
              elem.weekday === 5 &&
              this.agendaSelecionada._id == agendaVisconde
            ) {
              if (elem.hour == "17" && elem.minute > "00") return;
            }

            return {
              datetime: elem,
              idle: !horariosSelecionados.some(
                (hora) => hora.toJSON() === elem.toJSON()
              ),
              block:
                horariosSelecionados.filter((item) => item.equals(elem))
                  .length >= this.agendaSelecionada.quantidade,
            };
          })
          .filter((elem) => elem != undefined);

        this.horariosDisponiveis = horarios;
      } catch (e) {
        console.log(e);
      }
    },

    async uploadComprovante() {
      this.fileLoading = true;

      try {
        const formData = new FormData();

        formData.append("arquivo", this.fileComprovante);

        const headers = { "Content-Type": "multipart/form-data" };
        const { data } = await axios.post("/files/comprovantes", formData, {
          headers,
        });
        this.cadastro.pagamento.comprovante = data.id;
        this.cadastro.pagamento.status = false;
      } catch (err) {
        console.log(err);
        this.$noty.error(err.message);
      } finally {
        this.fileLoading = false;
      }
    },

    async uploadCNH() {
      this.fileLoading = true;

      try {
        const formData = new FormData();

        formData.append("arquivo", this.fileCNH);

        const headers = { "Content-Type": "multipart/form-data" };
        const { data } = await axios.post("/files/docs", formData, { headers });
        this.cadastro.arquivoCNH = data.id;
      } catch (err) {
        console.log(err);
        this.$noty.error(err.message);
      } finally {
        this.fileLoading = false;
      }
    },

    async uploadSocial() {
      this.fileLoading = true;

      try {
        const formData = new FormData();

        formData.append("arquivo", this.fileSocial);

        const headers = { "Content-Type": "multipart/form-data" };
        const { data } = await axios.post("/files/docs", formData, { headers });
        this.cadastro.arquivoSocial = data.id;
      } catch (err) {
        console.log(err);
        this.$noty.error(err.message);
      } finally {
        this.fileLoading = false;
      }
    },

    onPhoneValidate(formattedNumber, { number, valid }) {
      this.cadastro.telefone = number.international;
      this.tempPhone = number.national;

      if (!valid) {
        //
      }
    },

    onChangeAccount(e) {
      //if (!e) return false;

      if (typeof e === "object" && e !== null) {
        this.cadastro.belongsTo = e;
        return;
      }

      this.cadastro.belongsTo = {
        name: e,
        phoneNumber: "",
        email: "",
      };
    },

    setCliente(ev) {
      if (!ev) return false;

      this.cadastro.nome = ev.nome;
      this.cadastro.telefone = this.tempPhone = ev.phoneNumber;
      this.cadastro.cpfcnpj = ev.cpfcnpj;
      this.cadastro.atividade = ev.atividade;
      this.cadastro.account_id = ev._id;
      this.cadastro.email = ev.email;
      this.cadastro.belongsTo = ev.belongsTo ? ev.belongsTo : { name: "" };
      this.contaNome = ev.belongsTo?.name ?? "";
    },

    filterCliente(item, queryText) {
      const cpf = queryText.replace(/[^0-9]/g, "");
      if (
        cpf.length > 0 &&
        item.cpfcnpj.replace(/[^0-9]/g, "").indexOf(cpf) > -1
      ) {
        return true;
      }

      if (
        item.name &&
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      ) {
        return true;
      }
    },
  },

  mounted() {
    // console.log(this);
  },
};
</script>

<style></style>
