
import { getSession, setSession } from "./index"

export default {
    namespaced: true,
    state: {
        clientes:[],
        accountTypeLabel: {
            "CLIENTE_PF": "Cliente PF",
            "CLIENTE_PJ": "Cliente PJ",

        }

    },
    getters:{
        listClientes(state){
            return state.clientes.filter(el => {
               return el.accountType === "CLIENTE_PJ" || el.accountType === "CLIENTE_PF" 
            })
        }
    },
    mutations: {
        setClientes(state, payload) {
            state.clientes = payload;
        }
    },
    actions: {

        async fetchAllClientes({ commit }) {

            let clientes = await getSession("clientes");

            if (!clientes) {
                commit("syncModuleAdd", "clientes",  {root: true });
                const {data} = await axios.get(`/clientes`)
                await  setSession("clientes", data);
                clientes = data
            }

            commit('setClientes', clientes)
            commit("syncModuleFinished", "clientes",  {root: true });

        },
        
    }
}