<template>
    <div class="field">
        <label class="text-caption">{{label}}</label>
        <div class="text-body-1 font-weight-medium">
            {{ value }}
        </div>
    </div>
</template>

<script>

export default {
    name: "InputView",
    props: {
        label: String,
        value: String
    }
}

</script>

<style scoped>
    
</style>