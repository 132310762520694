import Tracker from '@openreplay/tracker';
import trackerAxios from '@openreplay/tracker-axios';
import trackerAssist from '@openreplay/tracker-assist';

// const isDev = (window.location.hostname == "localhost")? true : false;

const tracker = new Tracker({
  projectKey: "79QSvIIyJ3wGSCBggSr1",  
  __DISABLE_SECURE_MODE: true,
  //onStart: ({ sessionID }) => console.log("OpenReplay tracker started with session: ", sessionID),
});
// tracker.start();


tracker.use(trackerAxios());
tracker.use(trackerAssist());

export default tracker;
