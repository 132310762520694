<template>
  <div class="flex justify-center w-full">
    <vue-dropzone
      id="dropzone"
      ref="myDropzone"
      :use-custom-slot="true"
      :options="dropzoneOptions"
      @vdropzone-success="handleSuccess"
      @vdropzone-error="handleError"
    >
      <div class="dropzone-custom-content">
        <div><v-icon left>mdi-upload</v-icon>Upload {{ tipoDoc }}</div>
      </div></vue-dropzone
    >
  </div>
</template>

<script>
import VueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    VueDropzone,
  },
  props: {
    tipoDoc: {
      type: String,
      default: () => "",
    },
  },

  data() {
    const isDev = ["localhost", "dev.sdagenda.com.br"].includes(
      window.location.hostname
    );
    const ambiente = isDev ? "dev" : "padrao";
    const typeArquivo =
      this.tipoDoc === "Comprovante" ? "comprovantes" : "docs";
    const urlDropzone = `https://${ambiente}.sdagenda.com.br/api/files/${typeArquivo}`;
    return {
      dropzoneOptions: {
        url: urlDropzone,
        headers: {
          "x-access-token": "mongodb-direct",
        },
        method: "post",
        paramName: "arquivo",
        maxFilesize: 1, // 1 MB
        maxFiles: 1,
        addRemoveLinks: true,
        uploadMultiple: false,
        thumbnailWidth: 200,
        dictRemoveFile: "Remover arquivo",
        dictFileTooManyFiles: "Você não pode fazer upload de mais arquivos.",
        dictCancelUpload: "Cancelar upload",
        dictInvalidFileType: "Tipo de arquivo inválido",
        dictFileTooBig: "Arquivo muito grande, Tamanho máximo permitido: 1 MB.",
        acceptedFiles: "image/*,application/pdf",
      },
    };
  },
  methods: {
    handleSuccess(file, response) {
      this.$emit("upload-success", response);
    },
    handleError(file, errorMessage) {
      this.$noty.error(`erro: ${errorMessage}`);
      console.error(errorMessage);
      console.error(file.name);
      this.$refs.myDropzone.removeAllFiles();
    },
  },
};
</script>
<style>
.vue-dropzone > .dz-preview .dz-error-message {
  top: 0;
}
#dropzone {
  border-radius: 20px;
  border: 2px dashed gray;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropzone .dz-preview .dz-image {
  z-index: 0;
}
</style>
